export enum UserRolesEnum {
  SELLER = 0,
  MANAGER = 1,
  SUPERVISOR = 3,
}

export interface ApplicationSettingsInterface {
  creditType?: CreditTypes | number;
}

export enum CreditTypes {
  NO_SPOUSE = 0,
  PERSONAL = 1,
  SPOUSE = 2,
}

export enum AuthenticationMethods {
  NONE = 0,
  EMAIL = 1,
  SMS = 2,
}

export const CreditTypeOptions = [
  {
    // 'text' here is just a string that will be translated
    // text: "newApplication.creditTypeNoSpouse",
    text: "newApplication.creditTypePersonal",
    value: CreditTypes.NO_SPOUSE,
  },
  // {
  //   text: "newApplication.creditTypePersonal",
  //   value: CreditTypes.PERSONAL,
  // },
  {
    text: "newApplication.creditTypeSpouse",
    value: CreditTypes.SPOUSE,
  },
];

export interface CreditTypeOption {
  value: number;
  text: string;
  icon?: string;
  tooltip?: string;
}

export enum IncomeTypes {
  WORKER = 1,
  PENSIONER = 2,
  OTHER = 3,
  OFFICER = 4,
  FARMER = 7,
}

export const IncomeTypeOptions = [
  {
    text: "newApplication.incomeTypeHiredWorker",
    value: 1,
  },
  {
    text: "newApplication.incomeTypePensioner",
    value: 2,
  },
  {
    text: "newApplication.incomeTypeOther",
    value: 3,
  },
  {
    text: "newApplication.incomeTypeOfficer",
    value: 4,
  },
  {
    text: "newApplication.incomeTypeHiredFarmer",
    value: 7,
  },
];

export enum SignTypes {
  UNKNOWN_SIGN = 0,
  PAPER_SIGN = 1,
  CENT_SIGN = 10,
  E_SIGN = 11,
  TABLET_SIGN = 20,
  INI_SIGN = 30,
  INI_TABLET_SIGN = 31,
}

export interface ProposedOptionInterface {
  Trukme: number;
  MenesioImoka: number;
  Suma: number;
  ImokuSuma: number;
  PalukanuNorma: number;
  BKKMN: number;
  SutartiesMokestis: number;
  AdministravimoMokestis: number;
  PerkuTrukme?: number;
  PerkuImoka?: number;
  PerkuPavadinimas?: string;
  Draudimas?: number;
}

export enum ContractStatuses {
  REJECTED = -1,
  APPLICATION = 0,
  UNSIGNED = 1,
  SIGNED = 2,
  ARCHIVE = 3,
  DEFECTED = 4,
}

export enum ContractTypes {
  MONEY_IN_CACHE = 2,
  GOODS = 3,
  CREDIT_ACCOUNT = 5,
  CREDIT_CARD = 6,
  CREDIT = 7,
}

export enum TextContractTypes {
  MORTGAGE = "mortgage",
  CREDIT_CARDS = "CreditCards",
  LEASING = "Leasing",
  CONSUMER = "Consumer",
  OTHER = "Other",
}

// In old system ContractTypeOptions were defined like this:
// const TYPE_OF_CONTRACT_MORTGAGE = 'mortgage';
// const TYPE_OF_CONTRACT_CREDIT_CARD = 'CreditCards';
// const TYPE_OF_CONTRACT_LEASING = 'Leasing';
// const TYPE_OF_CONTRACT_CONSUMER = 'Consumer';
// const TYPE_OF_CONTRACT_OTHER = 'Other';
export const ContractTypeOptions = [
  {
    text: "newApplication.contractType.mortgage",
    value: TextContractTypes.MORTGAGE,
  },
  {
    text: "newApplication.contractType.creditCards",
    value: TextContractTypes.CREDIT_CARDS,
  },
  {
    text: "newApplication.contractType.leasing",
    value: TextContractTypes.LEASING,
  },
  {
    text: "newApplication.contractType.consumer",
    value: TextContractTypes.CONSUMER,
  },
  {
    text: "newApplication.contractType.other",
    value: TextContractTypes.OTHER,
  },
];

export enum EcreditStatuses {
  NONE = "-1",
  NORMAL = "0",
  REVIEWED_BY_MANAGER = "1",
  TERMINATED = "2",
  SIGNED_CONTRACT = "3",
  SIGNED_APPLICATION = "4",
}

export interface CreditConditionsOptionInterface {
  Akcijinis: string;
  GyvybesDraudimas: string;
  Nebegalioja: string;
  Pavadinimas: string;
  PerkuSalygos: string;
  Refinansavimas: string;
  SalyguVariantoID: string;
  SutartiesTipoID: string;
}

export enum DocumentTypes {
  PASSPORT = "0",
  ID_CARD = "1",
  DRIVER_LICENSE = "2",
  RESIDENCE_PERMIT = "3",
  NONE = "4",
  RESIDENCE_PERMIT_TEMPORARY = "5",
  E_PERMIT = "6",
}

export enum DocumentFileTypes {
  ID_CARD_FRONT = 3,
  ID_CARD_BACK = 4,
  ID_CARD_FRONT_SPOUSE = 5,
  ID_CARD_BACK_SPOUSE = 6,
  PASSPORT = 7,
  PASSPORT_SPOUSE = 8,
  REFINANCE = 15,
  RESIDENCE_PERMIT_FRONT = 18,
  RESIDENCE_PERMIT_BACK = 19,
  RESIDENCE_PERMIT_FRONT_SPOUSE = 20,
  RESIDENCE_PERMIT_BACK_SPOUSE = 21,
  RESIDENCE_PERMIT_TEMPORARY_FRONT = 22,
  RESIDENCE_PERMIT_TEMPORARY_BACK = 23,
  RESIDENCE_PERMIT_TEMPORARY_FRONT_SPOUSE = 24,
  RESIDENCE_PERMIT_TEMPORARY_BACK_SPOUSE = 25,
  ELECTRONIC_RESIDENCE_PERMIT_CHECK_PHOTO = 26,
  ELECTRONIC_RESIDENCE_PERMIT_CHECK_PHOTO_SPOUSE = 27,
  BANK_ACCOUNT_REPORT = 28,
  BANK_ACCOUNT_REPORT_SPOUSE = 29,
}

interface DocumentFileTypesToDocumentTypeInterface {
  [key: number]: DocumentTypes;
}

export const DocumentFileTypesToDocumentType: DocumentFileTypesToDocumentTypeInterface = {
  [DocumentFileTypes.ID_CARD_FRONT]: DocumentTypes.ID_CARD,
  [DocumentFileTypes.ID_CARD_BACK]: DocumentTypes.ID_CARD,
  [DocumentFileTypes.ID_CARD_FRONT_SPOUSE]: DocumentTypes.ID_CARD,
  [DocumentFileTypes.ID_CARD_BACK_SPOUSE]: DocumentTypes.ID_CARD,
  [DocumentFileTypes.PASSPORT]: DocumentTypes.PASSPORT,
  [DocumentFileTypes.PASSPORT_SPOUSE]: DocumentTypes.PASSPORT,
  // [DocumentFileTypes.REFINANCE]: 15,
  [DocumentFileTypes.RESIDENCE_PERMIT_FRONT]: DocumentTypes.RESIDENCE_PERMIT,
  [DocumentFileTypes.RESIDENCE_PERMIT_BACK]: DocumentTypes.RESIDENCE_PERMIT,
  [DocumentFileTypes.RESIDENCE_PERMIT_FRONT_SPOUSE]: DocumentTypes.RESIDENCE_PERMIT,
  [DocumentFileTypes.RESIDENCE_PERMIT_BACK_SPOUSE]: DocumentTypes.RESIDENCE_PERMIT,
  [DocumentFileTypes.RESIDENCE_PERMIT_TEMPORARY_FRONT]: DocumentTypes.RESIDENCE_PERMIT_TEMPORARY,
  [DocumentFileTypes.RESIDENCE_PERMIT_TEMPORARY_BACK]: DocumentTypes.RESIDENCE_PERMIT_TEMPORARY,
  [DocumentFileTypes.RESIDENCE_PERMIT_TEMPORARY_FRONT_SPOUSE]: DocumentTypes.RESIDENCE_PERMIT_TEMPORARY,
  [DocumentFileTypes.RESIDENCE_PERMIT_TEMPORARY_BACK_SPOUSE]: DocumentTypes.RESIDENCE_PERMIT_TEMPORARY,
  [DocumentFileTypes.ELECTRONIC_RESIDENCE_PERMIT_CHECK_PHOTO]: DocumentTypes.E_PERMIT,
  [DocumentFileTypes.ELECTRONIC_RESIDENCE_PERMIT_CHECK_PHOTO_SPOUSE]: DocumentTypes.E_PERMIT,
  // [DocumentFileTypes.BANK_ACCOUNT_REPORT]: 28,
  // [DocumentFileTypes.BANK_ACCOUNT_REPORT_SPOUSE]: 29,
};

export const ClientRequiredDocsByType = {
  [DocumentTypes.PASSPORT]: [DocumentFileTypes.PASSPORT],
  [DocumentTypes.ID_CARD]: [DocumentFileTypes.ID_CARD_FRONT, DocumentFileTypes.ID_CARD_BACK],
  [DocumentTypes.DRIVER_LICENSE]: [],
  [DocumentTypes.RESIDENCE_PERMIT]: [DocumentFileTypes.RESIDENCE_PERMIT_FRONT, DocumentFileTypes.RESIDENCE_PERMIT_BACK],
  [DocumentTypes.RESIDENCE_PERMIT_TEMPORARY]: [
    DocumentFileTypes.RESIDENCE_PERMIT_TEMPORARY_FRONT,
    DocumentFileTypes.RESIDENCE_PERMIT_TEMPORARY_BACK,
  ],
  [DocumentTypes.E_PERMIT]: [DocumentFileTypes.ELECTRONIC_RESIDENCE_PERMIT_CHECK_PHOTO],
  [DocumentTypes.NONE]: [
    DocumentFileTypes.PASSPORT,
    DocumentFileTypes.ID_CARD_FRONT,
    DocumentFileTypes.ID_CARD_BACK,
    DocumentFileTypes.RESIDENCE_PERMIT_FRONT,
    DocumentFileTypes.RESIDENCE_PERMIT_BACK,
    DocumentFileTypes.RESIDENCE_PERMIT_TEMPORARY_FRONT,
    DocumentFileTypes.RESIDENCE_PERMIT_TEMPORARY_BACK,
    DocumentFileTypes.ELECTRONIC_RESIDENCE_PERMIT_CHECK_PHOTO,
  ],
};

export const SpouseRequiredDocsByType = {
  [DocumentTypes.PASSPORT]: [],
  [DocumentTypes.ID_CARD]: [],
  [DocumentTypes.DRIVER_LICENSE]: [],
  [DocumentTypes.RESIDENCE_PERMIT]: [],
  [DocumentTypes.RESIDENCE_PERMIT_TEMPORARY]: [],
  [DocumentTypes.E_PERMIT]: [],
  [DocumentTypes.NONE]: [],
};

export const RequiresTwoDocumentSides = {
  [DocumentTypes.PASSPORT]: false,
  [DocumentTypes.ID_CARD]: true,
  [DocumentTypes.DRIVER_LICENSE]: false,
  [DocumentTypes.RESIDENCE_PERMIT]: true,
  [DocumentTypes.RESIDENCE_PERMIT_TEMPORARY]: true,
  [DocumentTypes.E_PERMIT]: false,
  [DocumentTypes.NONE]: false,
};

export enum CriteriaTypes {
  CHECKBOX = "1",
  TEXT = "2",
}

export enum MazesImokos {
  NO = "0",
  YES = "1",
}

export enum PajamuPazyma {
  NO = "0",
  YES = "1",
}

export interface Country {
  code: string;
  titleLt: string;
  titleEn: string;
}

export const Countries: Country[] = [
  {
    code: "LTU",
    titleLt: "Lietuva",
    titleEn: "Lithuania",
  },
  {
    code: "AFG",
    titleLt: "Afganistanas",
    titleEn: "Afghanistan",
  },
  {
    code: "ALA",
    titleLt: "Alandų salos",
    titleEn: "Åland Islands",
  },
  {
    code: "ALB",
    titleLt: "Albanija",
    titleEn: "Albania",
  },
  {
    code: "DZA",
    titleLt: "Alžyras",
    titleEn: "Algeria",
  },
  {
    code: "ASM",
    titleLt: "Amerikos Samoa",
    titleEn: "American Samoa",
  },
  {
    code: "AND",
    titleLt: "Andora",
    titleEn: "Andorra",
  },
  {
    code: "AGO",
    titleLt: "Angola",
    titleEn: "Angola",
  },
  {
    code: "AIA",
    titleLt: "Angilija",
    titleEn: "Anguilla",
  },
  {
    code: "ATA",
    titleLt: "Antarktida",
    titleEn: "Antarctica",
  },
  {
    code: "ATG",
    titleLt: "Antigvair Barbuda",
    titleEn: "Antiguaand Barbuda",
  },
  {
    code: "ARG",
    titleLt: "Argentina",
    titleEn: "Argentina",
  },
  {
    code: "ARM",
    titleLt: "Armėnija",
    titleEn: "Armenia",
  },
  {
    code: "ABW",
    titleLt: "Aruba",
    titleEn: "Aruba",
  },
  {
    code: "AUS",
    titleLt: "Australija",
    titleEn: "Australia",
  },
  {
    code: "AUT",
    titleLt: "Austrija",
    titleEn: "Austria",
  },
  {
    code: "AZE",
    titleLt: "Azerbaidžanas",
    titleEn: "Azerbaijan",
  },
  {
    code: "BHS",
    titleLt: "Bahamos",
    titleEn: "Bahamas",
  },
  {
    code: "BHR",
    titleLt: "Bahreinas",
    titleEn: "Bahrain",
  },
  {
    code: "BLR",
    titleLt: "Baltarusija",
    titleEn: "Belarus",
  },
  {
    code: "BGD",
    titleLt: "Bangladešas",
    titleEn: "Bangladesh",
  },
  {
    code: "BRB",
    titleLt: "Barbadosas",
    titleEn: "Barbados",
  },
  {
    code: "BEL",
    titleLt: "Belgija",
    titleEn: "Belgium",
  },
  {
    code: "BLZ",
    titleLt: "Belizas",
    titleEn: "Belize",
  },
  {
    code: "BEN",
    titleLt: "Beninas",
    titleEn: "Benin",
  },
  {
    code: "BMU",
    titleLt: "Bermuda",
    titleEn: "Bermuda",
  },
  {
    code: "BTN",
    titleLt: "Butanas",
    titleEn: "Bhutan",
  },
  {
    code: "BOL",
    titleLt: "Bolivija",
    titleEn: "Bolivia",
  },
  {
    code: "BES,",
    titleLt: "Sint Eustatijus ir Saba (BES salos)",
    titleEn: "Bonaire, Sint Eustatius and Saba",
  },
  {
    code: "BIH",
    titleLt: "Bosnija ir Hercegovina",
    titleEn: "Bosnia and Herzegovina",
  },
  {
    code: "BWA",
    titleLt: "Botsvana",
    titleEn: "Botswana",
  },
  {
    code: "BVT",
    titleLt: "Buvėsala",
    titleEn: "BouvetIsland",
  },
  {
    code: "BRA",
    titleLt: "Brazilija",
    titleEn: "Brazil",
  },
  {
    code: "BRN",
    titleLt: "Brunėjus",
    titleEn: "Brunei Darussalam",
  },
  {
    code: "BGR",
    titleLt: "Bulgarija",
    titleEn: "Bulgaria",
  },
  {
    code: "BFA",
    titleLt: "Burkina Faso",
    titleEn: "Burkina Faso",
  },
  {
    code: "BDI",
    titleLt: "Burundis",
    titleEn: "Burundi",
  },
  {
    code: "CAF",
    titleLt: "Centrinės Afrikos Respublika",
    titleEn: "Central African Republic",
  },
  {
    code: "TCD",
    titleLt: "Čadas",
    titleEn: "Chad",
  },
  {
    code: "CHL",
    titleLt: "Čilė",
    titleEn: "Chile",
  },
  {
    code: "CZE",
    titleLt: "Čekija",
    titleEn: "Czechia",
  },
  {
    code: "DNK",
    titleLt: "Danija",
    titleEn: "Denmark",
  },
  {
    code: "CIV",
    titleLt: "Dramblio Kaulo Krantas",
    titleEn: "Côte d'Ivoire",
  },
  {
    code: "DMA",
    titleLt: "Dominika",
    titleEn: "Dominica",
  },
  {
    code: "DOM",
    titleLt: "Dominikos Respublika",
    titleEn: "Dominican Republic",
  },
  {
    code: "DJI",
    titleLt: "Džibutis",
    titleEn: "Djibouti",
  },
  {
    code: "EGY",
    titleLt: "Egiptas",
    titleEn: "Egypt",
  },
  {
    code: "ECU",
    titleLt: "Ekvadoras",
    titleEn: "Ecuador",
  },
  {
    code: "ERI",
    titleLt: "Eritrėja",
    titleEn: "Eritrea",
  },
  {
    code: "EST",
    titleLt: "Estija",
    titleEn: "Estonia",
  },
  {
    code: "ETH",
    titleLt: "Etiopija",
    titleEn: "Ethiopia",
  },
  {
    code: "FRO",
    titleLt: "Farerų Salos",
    titleEn: "Faroe Islands",
  },
  {
    code: "FJI",
    titleLt: "Fidžis",
    titleEn: "Fiji",
  },
  {
    code: "FLK",
    titleLt: "Folklando (Malvinų) Salos",
    titleEn: "Falkland Islands (Malvinas)",
  },
  {
    code: "GAB",
    titleLt: "Gabonas",
    titleEn: "Gabon",
  },
  {
    code: "GMB",
    titleLt: "Gambija",
    titleEn: "Gambia",
  },
  {
    code: "GHA",
    titleLt: "Gana",
    titleEn: "Ghana",
  },
  {
    code: "GIB",
    titleLt: "Gibraltaras",
    titleEn: "Gibraltar",
  },
  {
    code: "GRC",
    titleLt: "Graikija",
    titleEn: "Greece",
  },
  {
    code: "GRL",
    titleLt: "Grenlandija",
    titleEn: "Greenland",
  },
  {
    code: "GRD",
    titleLt: "Grenada",
    titleEn: "Grenada",
  },
  {
    code: "GEO",
    titleLt: "Gruzija",
    titleEn: "Georgia",
  },
  {
    code: "GLP",
    titleLt: "Gvadelupa",
    titleEn: "Guadeloupe",
  },
  {
    code: "GUM",
    titleLt: "Guamas",
    titleEn: "Guam",
  },
  {
    code: "GTM",
    titleLt: "Gvatemala",
    titleEn: "Guatemala",
  },
  {
    code: "GGY",
    titleLt: "Gernsis",
    titleEn: "Guernsey",
  },
  {
    code: "GIN",
    titleLt: "Gvinėja",
    titleEn: "Guinea",
  },
  {
    code: "GNB",
    titleLt: "Bisau Gvinėja",
    titleEn: "Guinea-Bissau",
  },
  {
    code: "GUY",
    titleLt: "Gajana",
    titleEn: "Guyana",
  },
  {
    code: "HTI",
    titleLt: "Haitis",
    titleEn: "Haiti",
  },
  {
    code: "HMD",
    titleLt: "Herdo ir Makdonaldo salos",
    titleEn: "Heard Islandand McDonald Islands",
  },
  {
    code: "VAT",
    titleLt: "Vatikanas",
    titleEn: "HolySee",
  },
  {
    code: "HND",
    titleLt: "Hondūras",
    titleEn: "Honduras",
  },
  {
    code: "HKG",
    titleLt: "Honkongas",
    titleEn: "HongKong",
  },
  {
    code: "HUN",
    titleLt: "Vengrija",
    titleEn: "Hungary",
  },
  {
    code: "ISL",
    titleLt: "Islandija",
    titleEn: "Iceland",
  },
  {
    code: "IND",
    titleLt: "Indija",
    titleEn: "India",
  },
  {
    code: "IOT",
    titleLt: "Indijos Vandenyno Britų Sritis",
    titleEn: "British Indian Ocean Territory",
  },
  {
    code: "IDN",
    titleLt: "Indonezija",
    titleEn: "Indonesia",
  },
  {
    code: "IRN",
    titleLt: "Iranas",
    titleEn: "Iran",
  },
  {
    code: "IRQ",
    titleLt: "Irakas",
    titleEn: "Iraq",
  },
  {
    code: "IRL",
    titleLt: "Airija",
    titleEn: "Ireland",
  },
  {
    code: "IMN",
    titleLt: "Menosala",
    titleEn: "IsleofMan",
  },
  {
    code: "ISR",
    titleLt: "Izraelis",
    titleEn: "Israel",
  },
  {
    code: "ITA",
    titleLt: "Italija",
    titleEn: "Italy",
  },
  {
    code: "JAM",
    titleLt: "Jamaika",
    titleEn: "Jamaica",
  },
  {
    code: "JPN",
    titleLt: "Japonija",
    titleEn: "Japan",
  },
  {
    code: "JEY",
    titleLt: "Džersis",
    titleEn: "Jersey",
  },
  {
    code: "JOR",
    titleLt: "Jordanija",
    titleEn: "Jordan",
  },
  {
    code: "KHM",
    titleLt: "Kambodža",
    titleEn: "Cambodia",
  },
  {
    code: "CMR",
    titleLt: "Kamerūnas",
    titleEn: "Cameroon",
  },
  {
    code: "CAN",
    titleLt: "Kanada",
    titleEn: "Canada",
  },
  {
    code: "CYM",
    titleLt: "Kaimanų salos",
    titleEn: "Cayman Islands",
  },
  {
    code: "CHN",
    titleLt: "Kinija",
    titleEn: "China",
  },
  {
    code: "CXR",
    titleLt: "Kalėdų sala",
    titleEn: "Christmas Island",
  },
  {
    code: "CCK",
    titleLt: "Kokosų (Kilingo) Salos",
    titleEn: "Cocos (Keeling) Islands",
  },
  {
    code: "COL",
    titleLt: "Kolumbija",
    titleEn: "Colombia",
  },
  {
    code: "COM",
    titleLt: "Komorai",
    titleEn: "Comoros",
  },
  {
    code: "COG",
    titleLt: "Kongo Respublika",
    titleEn: "Congo",
  },
  {
    code: "COD",
    titleLt: "Kongo Demokratinė Respublika",
    titleEn: "Democratic Republic of the Congo",
  },
  {
    code: "COK",
    titleLt: "Kuko Salos",
    titleEn: "Cook Islands",
  },
  {
    code: "CRI",
    titleLt: "Kosta Rika",
    titleEn: "Costa Rica",
  },
  {
    code: "HRV",
    titleLt: "Kroatija",
    titleEn: "Croatia",
  },
  {
    code: "CUB",
    titleLt: "Kuba",
    titleEn: "Cuba",
  },
  {
    code: "CUW",
    titleLt: "Kiurasao",
    titleEn: "Curaçao",
  },
  {
    code: "CYP",
    titleLt: "Kipras",
    titleEn: "Cyprus",
  },
  {
    code: "KAZ",
    titleLt: "Kazachstanas",
    titleEn: "Kazakhstan",
  },
  {
    code: "KEN",
    titleLt: "Kenija",
    titleEn: "Kenya",
  },
  {
    code: "KIR",
    titleLt: "Kiribatis",
    titleEn: "Kiribati",
  },
  {
    code: "PRK",
    titleLt: "Šiaurės Korėja",
    titleEn: "North Korea",
  },
  {
    code: "KOR",
    titleLt: "Pietų Korėja",
    titleEn: "South Korea",
  },
  {
    code: "KWT",
    titleLt: "Kuveitas",
    titleEn: "Kuwait",
  },
  {
    code: "KGZ",
    titleLt: "Kirgizija",
    titleEn: "Kyrgyzstan",
  },
  {
    code: "LAO",
    titleLt: "Laosas",
    titleEn: "Lao People's Democratic Republic",
  },
  {
    code: "LVA",
    titleLt: "Latvija",
    titleEn: "Latvia",
  },
  {
    code: "LBN",
    titleLt: "Libanas",
    titleEn: "Lebanon",
  },
  {
    code: "LSO",
    titleLt: "Lesotas",
    titleEn: "Lesotho",
  },
  {
    code: "LBR",
    titleLt: "Liberija",
    titleEn: "Liberia",
  },
  {
    code: "LBY",
    titleLt: "Libija",
    titleEn: "Libya",
  },
  {
    code: "LIE",
    titleLt: "Lichtenšteinas",
    titleEn: "Liechtenstein",
  },
  {
    code: "LUX",
    titleLt: "Liuksemburgas",
    titleEn: "Luxembourg",
  },
  {
    code: "MAC",
    titleLt: "Makao",
    titleEn: "Macao",
  },
  {
    code: "MKD",
    titleLt: "Makedonija",
    titleEn: "North Macedonia",
  },
  {
    code: "MDG",
    titleLt: "Madagaskaras",
    titleEn: "Madagascar",
  },
  {
    code: "MWI",
    titleLt: "Malavis",
    titleEn: "Malawi",
  },
  {
    code: "MYS",
    titleLt: "Malaizija",
    titleEn: "Malaysia",
  },
  {
    code: "MDV",
    titleLt: "Maldyvai",
    titleEn: "Maldives",
  },
  {
    code: "MLI",
    titleLt: "Malis",
    titleEn: "Mali",
  },
  {
    code: "MLT",
    titleLt: "Malta",
    titleEn: "Malta",
  },
  {
    code: "MHL",
    titleLt: "Maršalo salos",
    titleEn: "Marshall Islands",
  },
  {
    code: "MTQ",
    titleLt: "Martinika",
    titleEn: "Martinique",
  },
  {
    code: "MRT",
    titleLt: "Mauritanija",
    titleEn: "Mauritania",
  },
  {
    code: "MUS",
    titleLt: "Mauricijus",
    titleEn: "Mauritius",
  },
  {
    code: "MYT",
    titleLt: "Majotas",
    titleEn: "Mayotte",
  },
  {
    code: "MEX",
    titleLt: "Meksika",
    titleEn: "Mexico",
  },
  {
    code: "FSM",
    titleLt: "Mikronezija",
    titleEn: "Micronesia",
  },
  {
    code: "MDA",
    titleLt: "Moldavija",
    titleEn: "Moldova",
  },
  {
    code: "MCO",
    titleLt: "Monakas",
    titleEn: "Monaco",
  },
  {
    code: "MNG",
    titleLt: "Mongolija",
    titleEn: "Mongolia",
  },
  {
    code: "MNE",
    titleLt: "Juodkalnija",
    titleEn: "Montenegro",
  },
  {
    code: "MSR",
    titleLt: "Montseratas",
    titleEn: "Montserrat",
  },
  {
    code: "MAR",
    titleLt: "Marokas",
    titleEn: "Morocco",
  },
  {
    code: "MOZ",
    titleLt: "Mozambikas",
    titleEn: "Mozambique",
  },
  {
    code: "MMR",
    titleLt: "Mianmaras",
    titleEn: "Myanmar",
  },
  {
    code: "NAM",
    titleLt: "Namibija",
    titleEn: "Namibia",
  },
  {
    code: "NRU",
    titleLt: "Nauru",
    titleEn: "Nauru",
  },
  {
    code: "NPL",
    titleLt: "Nepalas",
    titleEn: "Nepal",
  },
  {
    code: "NLD",
    titleLt: "Nyderlandai",
    titleEn: "Netherlands",
  },
  {
    code: "NCL",
    titleLt: "Naujoji Kaledonija",
    titleEn: "New Caledonia",
  },
  {
    code: "NZL",
    titleLt: "Naujoji Zelandija",
    titleEn: "New Zealand",
  },
  {
    code: "NIC",
    titleLt: "Nikaragva",
    titleEn: "Nicaragua",
  },
  {
    code: "NER",
    titleLt: "Nigeris",
    titleEn: "Niger",
  },
  {
    code: "NGA",
    titleLt: "Nigerija",
    titleEn: "Nigeria",
  },
  {
    code: "NIU",
    titleLt: "Niujė",
    titleEn: "Niue",
  },
  {
    code: "NFK",
    titleLt: "Norfolko sala",
    titleEn: "Norfolk Island",
  },
  {
    code: "MNP",
    titleLt: "Marianos Šiaurinės Salos",
    titleEn: "Northern Mariana Islands",
  },
  {
    code: "NOR",
    titleLt: "Norvegija",
    titleEn: "Norway",
  },
  {
    code: "OMN",
    titleLt: "Omanas",
    titleEn: "Oman",
  },
  {
    code: "PAK",
    titleLt: "Pakistanas",
    titleEn: "Pakistan",
  },
  {
    code: "PLW",
    titleLt: "Palau",
    titleEn: "Palau",
  },
  {
    code: "PSE",
    titleLt: "Palestina",
    titleEn: "Palestine",
  },
  {
    code: "PAN",
    titleLt: "Panama",
    titleEn: "Panama",
  },
  {
    code: "FRA",
    titleLt: "Prancūzija",
    titleEn: "France",
  },
  {
    code: "GUF",
    titleLt: "Prancūzijos Gviana",
    titleEn: "French Guiana",
  },
  {
    code: "PYF",
    titleLt: "Prancūzijos Polinezija",
    titleEn: "French Polynesia",
  },
  {
    code: "ATF",
    titleLt: "Prancūzijos Pietų Sritys",
    titleEn: "French Southern Territories",
  },
  {
    code: "PNG",
    titleLt: "Papua Naujoji Gvinėja",
    titleEn: "Papua New Guinea",
  },
  {
    code: "PRY",
    titleLt: "Paragvajus",
    titleEn: "Paraguay",
  },
  {
    code: "GNQ",
    titleLt: "Pusiaujo Gvinėja",
    titleEn: "Equatorial Guinea",
  },
  {
    code: "PER",
    titleLt: "Peru",
    titleEn: "Peru",
  },
  {
    code: "PHL",
    titleLt: "Filipinai",
    titleEn: "Philippines",
  },
  {
    code: "PCN",
    titleLt: "Pitkernas",
    titleEn: "Pitcairn",
  },
  {
    code: "POL",
    titleLt: "Lenkija",
    titleEn: "Poland",
  },
  {
    code: "PRT",
    titleLt: "Portugalija",
    titleEn: "Portugal",
  },
  {
    code: "PRI",
    titleLt: "Puerto Rikas",
    titleEn: "Puerto Rico",
  },
  {
    code: "QAT",
    titleLt: "Kataras",
    titleEn: "Qatar",
  },
  {
    code: "REU",
    titleLt: "Reunjonas",
    titleEn: "Réunion",
  },
  {
    code: "ROU",
    titleLt: "Rumunija",
    titleEn: "Romania",
  },
  {
    code: "RUS",
    titleLt: "Rusija",
    titleEn: "Russia",
  },
  {
    code: "RWA",
    titleLt: "Ruanda",
    titleEn: "Rwanda",
  },
  {
    code: "SLV",
    titleLt: "Salvadoras",
    titleEn: "El Salvador",
  },
  {
    code: "BLM",
    titleLt: "Šv. Bartolomėjaus sala",
    titleEn: "Saint Barthélemy",
  },
  {
    code: "SHN",
    titleLt: "Šv. Elenos, Dangun Žengimo, Tristanas da Kunja salos",
    titleEn: "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    code: "KNA",
    titleLt: "Sent Kitsas ir Nevis",
    titleEn: "Saint Kitts and Nevis",
  },
  {
    code: "LCA",
    titleLt: "Sent Lusija",
    titleEn: "Saint Lucia",
  },
  {
    code: "MAF",
    titleLt: "San Martenas",
    titleEn: "Saint Martin",
  },
  {
    code: "SPM",
    titleLt: "Sen Pjeras ir Mikelonas",
    titleEn: "Saint Pierre and Miquelon",
  },
  {
    code: "VCT",
    titleLt: "Sent Vinsentas ir Grenadinai",
    titleEn: "Saint Vincent and the Grenadines",
  },
  {
    code: "WSM",
    titleLt: "Samoa",
    titleEn: "Samoa",
  },
  {
    code: "SMR",
    titleLt: "San Marinas",
    titleEn: "San Marino",
  },
  {
    code: "STP",
    titleLt: "San Tomė ir Prinsipė",
    titleEn: "Sao Tome and Principe",
  },
  {
    code: "SAU",
    titleLt: "Saudo Arabija",
    titleEn: "Saudi Arabia",
  },
  {
    code: "SEN",
    titleLt: "Senegalas",
    titleEn: "Senegal",
  },
  {
    code: "SRB",
    titleLt: "Serbija",
    titleEn: "Serbia",
  },
  {
    code: "SYC",
    titleLt: "Seišeliai",
    titleEn: "Seychelles",
  },
  {
    code: "SLE",
    titleLt: "Siera Leonė",
    titleEn: "Sierra Leone",
  },
  {
    code: "SGP",
    titleLt: "Singapūras",
    titleEn: "Singapore",
  },
  {
    code: "SXM",
    titleLt: "Sint Martenas",
    titleEn: "Sint Maarten",
  },
  {
    code: "FIN",
    titleLt: "Suomija",
    titleEn: "Finland",
  },
  {
    code: "SVK",
    titleLt: "Slovakija",
    titleEn: "Slovakia",
  },
  {
    code: "SVN",
    titleLt: "Slovėnija",
    titleEn: "Slovenia",
  },
  {
    code: "SLB",
    titleLt: "Saliamono Salos",
    titleEn: "Solomon Islands",
  },
  {
    code: "SOM",
    titleLt: "Somalis",
    titleEn: "Somalia",
  },
  {
    code: "ZAF",
    titleLt: "PAR",
    titleEn: "South Africa",
  },
  {
    code: "SGS",
    titleLt: "Pietų Džordžijos ir Pietų Sandvičo Salos",
    titleEn: "South Georgia and the South Sandwich Islands",
  },
  {
    code: "SSD",
    titleLt: "Pietų Sudanas",
    titleEn: "South Sudan",
  },
  {
    code: "ESP",
    titleLt: "Ispanija",
    titleEn: "Spain",
  },
  {
    code: "LKA",
    titleLt: "Šri Lanka",
    titleEn: "Sri Lanka",
  },
  {
    code: "SDN",
    titleLt: "Sudanas",
    titleEn: "Sudan",
  },
  {
    code: "SUR",
    titleLt: "Surinamas",
    titleEn: "Suriname",
  },
  {
    code: "SJM",
    titleLt: "Svalbardo ir Jan Majeno salos",
    titleEn: "Svalbard and Jan Mayen",
  },
  {
    code: "SWZ",
    titleLt: "Svazilendas",
    titleEn: "Eswatini",
  },
  {
    code: "SWE",
    titleLt: "Švedija",
    titleEn: "Sweden",
  },
  {
    code: "CHE",
    titleLt: "Šveicarija",
    titleEn: "Switzerland",
  },
  {
    code: "SYR",
    titleLt: "Sirija",
    titleEn: "Syria",
  },
  {
    code: "TWN",
    titleLt: "Taivanas",
    titleEn: "Taiwan",
  },
  {
    code: "TJK",
    titleLt: "Tadžikija",
    titleEn: "Tajikistan",
  },
  {
    code: "TZA",
    titleLt: "Tanzanija",
    titleEn: "Tanzania",
  },
  {
    code: "THA",
    titleLt: "Tailandas",
    titleEn: "Thailand",
  },
  {
    code: "TLS",
    titleLt: "Rytų Timoras",
    titleEn: "Timor-Leste",
  },
  {
    code: "TGO",
    titleLt: "Togas",
    titleEn: "Togo",
  },
  {
    code: "TKL",
    titleLt: "Tokelau",
    titleEn: "Tokelau",
  },
  {
    code: "TON",
    titleLt: "Tonga",
    titleEn: "Tonga",
  },
  {
    code: "TTO",
    titleLt: "Trinidadas ir Tobagas",
    titleEn: "Trinidad and Tobago",
  },
  {
    code: "TUN",
    titleLt: "Tunisas",
    titleEn: "Tunisia",
  },
  {
    code: "TUR",
    titleLt: "Turkija",
    titleEn: "Turkey",
  },
  {
    code: "TKM",
    titleLt: "Turkmėnija",
    titleEn: "Turkmenistan",
  },
  {
    code: "TCA",
    titleLt: "Terksas ir Kaikosas",
    titleEn: "Turks and Caicos Islands",
  },
  {
    code: "TUV",
    titleLt: "Tuvalu",
    titleEn: "Tuvalu",
  },
  {
    code: "UGA",
    titleLt: "Uganda",
    titleEn: "Uganda",
  },
  {
    code: "UKR",
    titleLt: "Ukraina",
    titleEn: "Ukraine",
  },
  {
    code: "ARE",
    titleLt: "Jungtiniai Arabų Emyratai",
    titleEn: "United Arab Emirates",
  },
  {
    code: "GBR",
    titleLt: "Jungtinė Karalystė",
    titleEn: "United Kingdom of Great Britain and Northern Ireland",
  },
  {
    code: "USA",
    titleLt: "Jungtinės Valstijos",
    titleEn: "United States of America",
  },
  {
    code: "UMI",
    titleLt: "JAV užjūrio mažosios salos",
    titleEn: "United States Minor Outlying Islands",
  },
  {
    code: "URY",
    titleLt: "Urugvajus",
    titleEn: "Uruguay",
  },
  {
    code: "UZB",
    titleLt: "Uzbekistanas",
    titleEn: "Uzbekistan",
  },
  {
    code: "VUT",
    titleLt: "Vanuatu",
    titleEn: "Vanuatu",
  },
  {
    code: "VEN",
    titleLt: "Venesuela",
    titleEn: "Venezuela",
  },
  {
    code: "DEU",
    titleLt: "Vokietija",
    titleEn: "Germany",
  },
  {
    code: "VNM",
    titleLt: "Vietnamas",
    titleEn: "Vietnam",
  },
  {
    code: "VGB",
    titleLt: "Mergelių Salos (Didžioji Britanija)",
    titleEn: "Virgin Islands (British)",
  },
  {
    code: "VIR",
    titleLt: "Mergelių Salos (JAV)",
    titleEn: "Virgin Islands (U.S.)",
  },
  {
    code: "WLF",
    titleLt: "Volisas ir Futūna",
    titleEn: "Wallis and Futuna",
  },
  {
    code: "ESH",
    titleLt: "Vakarų Sahara",
    titleEn: "Western Sahara",
  },
  {
    code: "YEM",
    titleLt: "Jemenas",
    titleEn: "Yemen",
  },
  {
    code: "ZMB",
    titleLt: "Zambija",
    titleEn: "Zambia",
  },
  {
    code: "ZWE",
    titleLt: "Zimbabvė",
    titleEn: "Zimbabwe",
  },
  {
    code: "CPV",
    titleLt: "Žaliasis Kyšulys",
    titleEn: "Cabo Verde",
  },
];

export interface IncomeVariantsInterface {
  PapildomiDuomenys: string;
  VariantoID: number;
  VariantoPav: string;
}

export const NETWORK_ERROR_CODE = -404;

export enum GlobalStorageKeys {
  APPLICATION_USER = "user",
  APPLICATION_USER_ADDITIONAL_INFO = "userAdditionalInfo",
  APPLICATIONS_IN_SESSION = "applicationsInSession",
  APPLICATION_FILTER_PARAMS = "applicationFilterParams",
  APPLICATION_FIELD_PARAMS = "applicationsFieldParams",
  APPLICATIONS_PER_PAGE = "applicationsPerPage",
  APPLICATIONS_PAGINATION_PAGE = "applicationsPaginationPage",
  FLUID_CONTAINER_FOR_TABLE = "fluidContainerForTable",
  SW_SKIP_WAITING = "skipWaiting",
  SESSION_EXPIRES = "sessionExpires",
  APPLICATION_LOCALE = "locale",
  SELLERS_PER_PAGE = "sellersPerPage",
  REPORTS_PER_PAGE = "reportsPerPage",
  DEVICE_VERIFICATION_COOKIE = "deviceId", // to allow multipple users to use the same device as trusted real cookie namie will be deviceId-userName
  INTENDED_URL = "intended_url",
  INTENDED_ROLE = "intended_role",
  UI_SCALE = "ui-zoom",
  UPDATE_NOTE_VISIBLE = "updateNoteVisible",
  PENDING_2FA_VERIFICATION = "pending2fa",
}

export enum ApplicationStandardInfoErrors {
  OK = "0",
  ERROR_WITH_MESSAGE = "1800",
  ERROR_WITH_SIMPLE_HTML_MESSAGE = "1801",
  ERROR_WITH_HTML_MESSAGE = "1802",
}

// this should not be used in any other scenarios except in already used one
export const ApplicationStandardInfoAllowedErrors = [
  ApplicationStandardInfoErrors.OK,
  ApplicationStandardInfoErrors.ERROR_WITH_HTML_MESSAGE,
];

export const ApplicationProposedOptionsAllowedErrors = [
  ApplicationStandardInfoErrors.OK,
  ApplicationStandardInfoErrors.ERROR_WITH_SIMPLE_HTML_MESSAGE,
  ApplicationStandardInfoErrors.ERROR_WITH_HTML_MESSAGE,
];

export enum SystemDefaults {
  MANUFACTURERS_WARRANTY_YEARS = 2,
  SESSION_CHECKING_TIMEOUT_MS = 10000,
  PERSONAL_CODE_INIT = 0,
  INCOME_TYPE_INIT = 0,
  DEFAULT_ID_EXPIRATION_DATE = "1990-01-01T00:00:00",
}

export enum UploadFileReadingResult {
  IMAGE = "img",
  PDF = "pdf",
  FAILED = "failed",
  UNKNOWN = "unknown",
}

// indicates in which format data comes from microblink recognition engine
export const MICROBLINK_IMG_FILE_FORMAT = "png";

export const CanEditPersonalCodeOnSignStatuses = [SignTypes.UNKNOWN_SIGN, SignTypes.INI_TABLET_SIGN, SignTypes.INI_SIGN];

export const TabletSigningRequiredSignStatuses = [
  SignTypes.UNKNOWN_SIGN,
  SignTypes.PAPER_SIGN,
  SignTypes.INI_SIGN,
  SignTypes.INI_TABLET_SIGN,
];

export enum ApplicationSteps {
  STEP_4 = 4,
  STEP_5 = 5,
}

export enum BankReportStatus {
  UNKNOWN = "0",
  PENDING = "1",
  RECEIVED = "2",
  INVALID = "-1",
}

export interface ServiceInterface {
  SistemosID: number;
  Pavadinimas: string;
  Statusas: ServiceStatus;
  Atnaujinta: string;
  Url?: string;
}

export enum ServiceStatus {
  OK = 0,
  SLOW = 1,
  ERROR = 2,
}

export enum InitiationConditions {
  FORBIDDEN = 0, // - neleidžiama inicijuoti pasirašymo nuotoliu
  ALLOWED = 1, // - visada leidžiama inicijuoti pasirašymą nuotoliu
  ALLOWED_WITH_ATTACHED_DOCUMENTS = 2, // - leidžiama inicijuoti tik prisegus paraiškos ir rinkodaros lapo kopijas
  ALLOWED_WHEN_EL_SIGNED = 3, // - leidžiama inicijuoti tik kai paraiška pasirašyta el. priemonėmis
}
