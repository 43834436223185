import Vue from "vue";

export const capitalize = (value: string) => {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const currency = (value: string | number): string => {
  // logic is ported from the old system
  // alternative ? https://stackoverflow.com/questions/43208012/how-do-i-format-currencies-in-a-vue-component
  let n = value;
  let decimals = 2;
  const thousandsSeparator = "";
  const decimalPoint = ".";

  const toFixedFix = function (n: number, decimals: number) {
    const k = Math.pow(10, decimals);
    return (Math.round(n * k) / k).toString();
  };

  n = !isFinite(+n) ? 0 : +n;
  decimals = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = typeof thousandsSeparator === "undefined" ? "," : thousandsSeparator;
  const dec = typeof decimalPoint === "undefined" ? "." : decimalPoint;

  let s = decimals > 0 ? toFixedFix(n, decimals) : toFixedFix(Math.round(n), decimals);
  //fix for IE parseFloat(0.55).toFixed(0) = 0;

  const abs = toFixedFix(Math.abs(n), decimals);
  let _, i;

  if (+abs >= 1000) {
    _ = abs.split(/\D/);
    i = _[0].length % 3 || 3;

    _[0] = s.slice(0, i + Number(n < 0)) + _[0].slice(i).replace(/(\d{3})/g, sep + "$1");
    s = _.join(dec);
  } else {
    s = s.replace(".", dec);
  }

  const decPos = s.indexOf(dec);
  if (decimals >= 1 && decPos !== -1 && s.length - decPos - 1 < decimals) {
    s += new Array(decimals - (s.length - decPos - 1)).join("0") + "0";
  } else if (decimals >= 1 && decPos === -1) {
    s += dec + new Array(decimals).join("0") + "0";
  }
  return s;
};

export const phone = (value: string | number) => {
  if (!value) {
    return "";
  }

  value = value.toString();

  if (value.length < 8) {
    return value;
  }

  if (value.charAt(0) == "8") {
    value = value.substring(1);
  }

  value = value.replaceAll("-", "");
  return value.charAt(0) !== "+" ? `+370 ${value.substr(0, 3)} ${value.substr(3)}` : value;
};

export const ipPhoneNumber = (value: string | number) => {
  return phone(value).replaceAll(" ", "").replaceAll("-", "");
};

export const phoneNoCode = (value: string | number) => {
  if (!value) {
    return "";
  }

  value = value.toString();

  if (value.length < 8) {
    return value;
  }

  if (value.charAt(0) == "8") {
    value = value.substring(1);
  }

  value = value.replaceAll("-", "");
  value = value.replaceAll("+370", "");
  return `${value.substring(0, 3)}${value.substring(3)}`;
};

export const date = (value: string | number) => {
  if (!value) return "";
  value = value.toString();
  return value.length == 19 ? value.substr(0, 10) : value;
};

export const dateTime = (value: string | number) => {
  if (!value) return "";
  value = value.toString();
  return value.length == 19 ? value.substr(0, 16) : value;
};

export const arrayUniqueBy = (arr: [], key: string) => {
  return arr.filter((v, i, a) => a.findIndex((t) => t[key] === v[key]) === i);
};

// for now hardcoding 26 chars
export const excerpt = (value: string) => {
  if (!value) return "";
  value = value.toString();
  return value.substring(0, 25);
};

export const truncate = (text: string, stop: number, clamp = "...") => {
  return !text || !text.length ? text : !stop || stop === 0 ? text : text.slice(0, stop) + (stop < text.length ? clamp || "..." : "");
};

Vue.filter("capitalize", capitalize);
Vue.filter("excerpt", excerpt);
Vue.filter("currency", currency);
Vue.filter("arrayUniqueBy", arrayUniqueBy);
Vue.filter("date", date);
Vue.filter("dateTime", dateTime);
Vue.filter("phone", phone);
Vue.filter("phoneNoCode", phoneNoCode);
Vue.filter("truncate", truncate);
Vue.filter("ipPhoneNumber", ipPhoneNumber);

export default {
  capitalize: Vue.filter("capitalize"),
  currency: Vue.filter("currency"),
  phone: Vue.filter("phone"),
  phoneNoCode: Vue.filter("phoneNoCode"),
  date: Vue.filter("date"),
  dateTime: Vue.filter("dateTime"),
  arrayUniqueBy: Vue.filter("arrayUniqueBy"),
  ipPhoneNumber: Vue.filter("ipPhoneNumber"),
};
