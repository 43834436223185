import { IncomeDataInterface, IncomeDataWithTotalsInterface } from "./../interfaces/incomeData";
import mixins from "vue-typed-mixins";
import fileDownload from "@/mixins/fileDownload";
import SoapClient from "@/lib/soap/soapClient";
import {
  GautiAtaskaitosDuomenisParametraiInterface,
  GautiAtaskaitosDuomenisParametraiWrapperInterface,
  IsrasoStatusasResponseInterface,
  IssaugotiPajamuSarasaIrasoInterface,
  IssaugotiPajamuSarasaIrasoInterfaceWrapperInterface,
  soapActions,
  SoapBodyParams,
  SoapOK,
} from "@/lib/soap/soapParams";
import { mapActions, mapGetters } from "vuex";
import { TranslateResult } from "vue-i18n";
import formatters from "./formatters";
import { BuyerInterface } from "@/interfaces/buyer";
import { ContractRequestEntryInterface, GautiSiulomusVariantus2Response } from "@/interfaces/application";
import { SellerInterface } from "@/interfaces/seller";
import { ReportsFormParamsInterface } from "@/interfaces/reports";
import {
  ApplicationProposedOptionsAllowedErrors,
  ApplicationStandardInfoAllowedErrors,
  ApplicationStandardInfoErrors,
  ProposedOptionInterface,
} from "@/interfaces/applicationSettings";

// read more about this approach of using mixins
// https://github.com/ktsn/vue-typed-mixins

const soapRequests = mixins(fileDownload, formatters).extend({
  computed: {
    ...mapGetters({
      applicationSettings: "newApplication/applicationSettings",
    }),
  },
  methods: {
    ...mapGetters({
      user: "auth/user",
      currentSeller: "newApplication/seller",
      buyer: "newApplication/buyer",
      buyerSpouse: "newApplication/buyerSpouse",
      application: "newApplication/application",
      goods: "newApplication/goods",
      notifications: "notifications/items",
      soapErrorCount: "notifications/soapErrorCount",
    }),
    ...mapActions({
      storeBuyer: "newApplication/storeBuyer",
      patchBuyer: "newApplication/patchBuyer",
      storeBuyerSpouse: "newApplication/storeBuyerSpouse",
      patchBuyerSpouse: "newApplication/patchBuyerSpouse",
      storeApplication: "newApplication/storeApplication",
      patchApplication: "newApplication/patchApplication",
      storeApplicationSettings: "newApplication/storeApplicationSettings",
      storeProposedOptions: "newApplication/storeProposedOptions",
      showError: "notifications/showError",
      showHtmlError: "notifications/showHtmlError",
      showSoapError: "notifications/showSoapError",
      showSuccess: "notifications/showSuccess",
      showHtmlSuccess: "notifications/showHtmlSuccess",
      showInfo: "notifications/showInfo",
      showHtmlInfo: "notifications/showHtmlInfo",
      showWarning: "notifications/showWarning",
      clearNotifications: "notifications/dropAll",
    }),
    soapGautiNaujaParaiska2() {
      const params: SoapBodyParams = {
        [soapActions.GautiNaujaParaiska2]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:pardavejoID": this.currentSeller().PardavejoID,
        },
      };

      return new SoapClient().sendRequest(soapActions.GautiNaujaParaiska2, params);
    },
    soapTikrintiAsmensDuomenis(personalCode: string) {
      const params: SoapBodyParams = {
        [soapActions.TikrintiAsmensDuomenis]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:asmensKodas": personalCode,
        },
      };

      return new Promise<BuyerInterface>((resolve, reject) => {
        new SoapClient()
          .sendRequest(soapActions.TikrintiAsmensDuomenis, params)
          .then((response) => {
            const buyerInfo: BuyerInterface = {
              Vardas: response.Vardas,
              Pavarde: response.Pavarde,
            };

            resolve(buyerInfo);
          })
          .catch((error) => {
            // this.showSoapError(error);
            reject(error);
          });
      });
    },
    soapLeidimuSarasas() {
      const params: SoapBodyParams = {
        [soapActions.LeidimuSarasas]: {
          "ns1:sesija": this.user().Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
        },
      };

      return new Promise<ContractRequestEntryInterface[]>((resolve, reject) => {
        new SoapClient()
          .sendRequest(soapActions.LeidimuSarasas, params)
          .then((response) => {
            if (response.KlaidosPranesimas) {
              this.showSoapError(response);
              reject([]);

              return;
            }

            if (!response.Leidimai) {
              return [];
            }

            const list = Array.isArray(response.Leidimai?.Leidimas) ? response.Leidimai?.Leidimas : [response.Leidimai?.Leidimas];

            const keysToHide = response.SlepiamiLaukai?.length ? response.SlepiamiLaukai.split(",") : [];

            list.map((item: any) => {
              Object.keys(item).forEach((key) => {
                if (keysToHide.includes(key)) {
                  delete item[key];
                }
              });
            });

            resolve(list);
          })
          .catch((e) => {
            this.showSoapError(e);
            reject([]);
          });
      });
    },
    soapLeidimaPatvirtinti(requestId: number, comment: string) {
      const params: SoapBodyParams = {
        [soapActions.LeidimaPatvirtinti]: {
          "ns1:sesija": this.user().Sesija,
          "ns1:leidimoID": requestId,
          "ns1:komentaras": comment,
        },
      };

      return new Promise((resolve, reject) => {
        new SoapClient()
          .sendRequest(soapActions.LeidimaPatvirtinti, params)
          .then((response) => {
            if (response.KlaidosPranesimas) {
              this.showSoapError(response);
              reject(false);

              return;
            }

            resolve(true);
          })
          .catch((e) => {
            this.showSoapError(e);
            reject(false);
          });
      });
    },
    soapLeidimaAtmesti(requestId: number, comment: string) {
      const params: SoapBodyParams = {
        [soapActions.LeidimaAtmesti]: {
          "ns1:sesija": this.user().Sesija,
          "ns1:leidimoID": requestId,
          "ns1:komentaras": comment,
        },
      };

      return new Promise((resolve, reject) => {
        new SoapClient()
          .sendRequest(soapActions.LeidimaAtmesti, params)
          .then((response) => {
            if (response.KlaidosPranesimas) {
              this.showSoapError(response);
              reject(false);

              return;
            }

            resolve(true);
          })
          .catch((e) => {
            this.showSoapError(e);
            reject(false);
          });
      });
    },
    soapLeidimaPrasyti(comment: string) {
      const params: SoapBodyParams = {
        [soapActions.LeidimaPrasyti]: {
          "ns1:sesija": this.user().Sesija,
          "ns1:paraiska": this.application(),
          "ns1:komentaras": comment,
        },
      };

      return new Promise((resolve, reject) => {
        new SoapClient()
          .sendRequest(soapActions.LeidimaPrasyti, params)
          .then((response) => {
            if (response.KlaidosPranesimas) {
              this.showSoapError(response);
              reject(false);

              return;
            }

            resolve(true);
          })
          .catch((e) => {
            this.showSoapError(e);
            reject(false);
          });
      });
    },
    soapIssaugotiParaiska() {
      const paraiskosParams: SoapBodyParams = {
        [soapActions.IssaugotiParaiska2]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiska": {
            "ns1:ParaiskosID": this.application().ParaiskosID,
            "ns1:PardavejoID": this.currentSeller().PardavejoID,
            "ns1:PardavejoAtstovas": this.currentSeller().VardasPavarde,
            "ns1:SutartiesNr": this.application().SutartiesNr,
            "ns1:AsmensKodas": this.application().AsmensKodas,
            "ns1:Suma": this.application().Suma,
            "ns1:DokumentoNr": this.application().DokumentoNr,
            "ns1:Vardas": this.application().Vardas || "",
            "ns1:Pavarde": this.application().Pavarde || "",
            "ns1:DokumentoTipoID": this.application().DokumentoTipoID,
            "ns1:DokumentoGaliojimoData": this.application().DokumentoGaliojimoData,
            "ns1:SutuoktinioAsmensKodas": this.application().SutuoktinioAsmensKodas,
            "ns1:SutuoktinioVardas": this.application().SutuoktinioVardas || "",
            "ns1:SutuoktinioPavarde": this.application().SutuoktinioPavarde || "",
            "ns1:SutuoktinioDokumentoTipoID": this.application().SutuoktinioDokumentoTipoID,
            "ns1:SutuoktinioDokumentoNr": this.application().SutuoktinioDokumentoNr,
            "ns1:SutuoktinioDokumentoGaliojimoData": this.application().SutuoktinioDokumentoGaliojimoData,
            "ns1:Sutuoktinis": this.application().Sutuoktinis,
            "ns1:Pajamos": this.application().Pajamos,
            "ns1:MazesPajamos": this.application().MazesPajamos,
            "ns1:BusimosPajamos": this.application().BusimosPajamos,
            "ns1:SutuoktinioPajamos": this.application().SutuoktinioPajamos,
            "ns1:SutuoktinioMazesPajamos": this.application().SutuoktinioMazesPajamos,
            "ns1:SutuoktinioBusimosPajamos": this.application().SutuoktinioBusimosPajamos,
            "ns1:KlientoVartojimoPaskola": this.application().KlientoVartojimoPaskola || 0,
            "ns1:KlientoBustoPaskola": this.application().KlientoBustoPaskola || 0,
            "ns1:SalyguVariantoID": this.application().SalyguVariantoID,
            "ns1:Trukme": this.application().Trukme,
            "ns1:Gatve": this.application().Gatve,
            "ns1:Namas": this.application().Namas,
            "ns1:Butas": this.application().Butas,
            "ns1:Miestas": this.application().Miestas,
            "ns1:Indeksas": this.application().Indeksas,
            "ns1:Telefonas": this.application().Telefonas,
            "ns1:Telefonas2": this.application().Telefonas2,
            "ns1:SaskaitosNr": this.application().SaskaitosNr,
            "ns1:MokejimoDiena": this.application().MokejimoDiena ?? 0,
            "ns1:Busena": this.application().Busena,
            "ns1:Data": this.application().Data,
            "ns1:DokumentuVariantai": this.application().DokumentuVariantai,
            "ns1:AtaskaitosTipas": this.application().AtaskaitosTipas,
            "ns1:AtsiemimoVietosID": this.application().AtsiemimoVietosID,
            "ns1:Avansas": this.application().Avansas,
            "ns1:AvansoTipas": this.application().AvansoTipas,
            "ns1:Butas2": this.application().Butas2,
            "ns1:DalyvaujaPolitikoje": this.application().DalyvaujaPolitikoje,
            "ns1:Darboviete": this.application().Darboviete,
            "ns1:DraudimoID": this.application().DraudimoID,
            "ns1:DraudimoImoka": this.application().DraudimoImoka,
            "ns1:PatikslintaDraudimoImoka": this.application().PatikslintaDraudimoImoka,
            "ns1:DraudimoPolisas": this.application().DraudimoPolisas,
            "ns1:DuomenuPolitikosGavimas": this.application().DuomenuPolitikosGavimas,
            "ns1:ElPastas": this.application().ElPastas,
            "ns1:Faktura": this.application().Faktura,
            "ns1:Gatve2": this.application().Gatve2,
            "ns1:GyvenamosVietosTipas": this.application().GyvenamosVietosTipas,
            "ns1:Indeksas2": this.application().Indeksas2,
            "ns1:InicijavimasGalimas": this.application().InicijavimasGalimas,
            "ns1:IsipareigojimuSuma": this.application().IsipareigojimuSuma,
            "ns1:IslaikytiniuSkaicius": this.application().IslaikytiniuSkaicius,
            "ns1:KitiKreditai": this.application().KitiKreditai,
            "ns1:KitosAplinkybes": this.application().KitosAplinkybes,
            "ns1:KlientoKitaPaskola": this.application().KlientoKitaPaskola || 0,
            "ns1:KlientoLizingoPaskola": this.application().KlientoLizingoPaskola,
            "ns1:KontrolineEilute": this.application().KontrolineEilute,
            "ns1:KreditoLimitas": this.application().KreditoLimitas,
            "ns1:KreditoNaudojimas": this.application().KreditoNaudojimas,
            "ns1:Kreditoriai": this.application().Kreditoriai,
            "ns1:MazosImokos": this.application().MazosImokos,
            "ns1:Miestas2": this.application().Miestas2,
            "ns1:Namas2": this.application().Namas2,
            "ns1:MokejimuGrafikas": this.application().MokejimuGrafikas,
            "ns1:PajamuPazyma": this.application().PajamuPazyma,
            "ns1:PajamuTipas": this.application().PajamuTipas,
            "ns1:PajamuTipasSarasui": this.application().PajamuTipasSarasui,
            "ns1:PajamuVariantai": this.application().PajamuVariantai,
            "ns1:PapildomaSaskaita": this.application().PapildomaSaskaita,
            "ns1:PapildomasKodas": this.application().PapildomasKodas,
            "ns1:PapildomoKreditoPabaiga": this.application().PapildomoKreditoPabaiga,
            "ns1:PapildomoKreditoSuma": this.application().PapildomoKreditoSuma,
            "ns1:ParaiskosPasirasymas": this.application().ParaiskosPasirasymas,
            "ns1:ParduotuvesPavadinimas": this.application().ParduotuvesPavadinimas,
            "ns1:PasirasymoVieta": this.application().PasirasymoVieta,
            "ns1:PaskutinisPasirasymas": this.application().PaskutinisPasirasymas,
            "ns1:Pastabos": this.application().Pastabos,
            "ns1:Pilietybe": this.application().Pilietybe,
            "ns1:PiniguPanaudojimas": this.application().PiniguPanaudojimas,
            "ns1:PiniguPanaudojimoVariantai": this.application().PiniguPanaudojimoVariantai,
            "ns1:PlanuojaIsipareigojimu": this.application().PlanuojaIsipareigojimu,
            "ns1:PlanuojamuIsipareigojimuSuma": this.application().PlanuojamuIsipareigojimuSuma,
            "ns1:PolitikoPareigos": this.application().PolitikoPareigos,
            "ns1:PolitikoRysys": this.application().PolitikoRysys,
            "ns1:PolitikoVardasPavarde": this.application().PolitikoVardasPavarde,
            "ns1:Pranesimas": this.application().Pranesimas,
            "ns1:Refinansavimas": this.application().Refinansavimas,
            "ns1:SutikimasPasiulymu": this.application().SutikimasPasiulymu,
            "ns1:SutikimasPartneriu": this.application().SutikimasPartneriu,
            "ns1:SutikimasProfiliavimo": this.application().SutikimasProfiliavimo,
            "ns1:SutuoktinioSutikimasPasiulymu": this.application().SutuoktinioSutikimasPasiulymu,
            "ns1:SutuoktinioSutikimasPartneriu": this.application().SutuoktinioSutikimasPartneriu,
            "ns1:SutuoktinioSutikimasProfiliavimo": this.application().SutuoktinioSutikimasProfiliavimo,
            "ns1:SalyguVariantai": this.application().SalyguVariantai,
            "ns1:SalyguVariantasSarasui": this.application().SalyguVariantasSarasui,
            "ns1:SeimosNariuSkaicius": this.application().SeimosNariuSkaicius,
            "ns1:SeimyninePadetis": this.application().SeimyninePadetis,
            "ns1:SonTGalimas": this.application().SonTGalimas,
            "ns1:Stazas": this.application().Stazas,
            "ns1:SutartiesPasirasymas": this.application().SutartiesPasirasymas,
            "ns1:SutartiesTipas": this.application().SutartiesTipas,
            "ns1:SutuoktinioDarboviete": this.application().SutuoktinioDarboviete,
            "ns1:SutuoktinioDokumentuVariantai": this.application().SutuoktinioDokumentuVariantai,
            "ns1:SutuoktinioElPastas": this.application().SutuoktinioElPastas,
            "ns1:SutuoktinioKontrolineEilute": this.application().SutuoktinioKontrolineEilute,
            "ns1:SutuoktinioPajamuPazyma": this.application().SutuoktinioPajamuPazyma,
            "ns1:SutuoktinioPajamuTipas": this.application().SutuoktinioPajamuTipas,
            "ns1:SutuoktinioParaiskosPasirasymas": this.application().SutuoktinioParaiskosPasirasymas,
            "ns1:SutuoktinioPilietybe": this.application().SutuoktinioPilietybe,
            "ns1:SutuoktinioTelefonas": this.application().SutuoktinioTelefonas,
            "ns1:TikrinimoData": this.application().TikrinimoData,
            "ns1:TuriIsieskojimu": this.application().TuriIsieskojimu,
            "ns1:TuriIsipareigojimu": this.application().TuriIsipareigojimu,
            "ns1:TuriVelavimu": this.application().TuriVelavimu,
            "ns1:TurtoRusys": this.application().TurtoRusys,
            "ns1:Valiuta": this.application().Valiuta,
            "ns1:VardasKorteleje": this.application().VardasKorteleje,
            "ns1:Prekes": this.goods(),
            "ns1:KatalogoAkcijosIsimtis": this.application().KatalogoAkcijosIsimtis,
          },
        },
      };

      return new Promise((resolve, reject) => {
        new SoapClient()
          .sendRequest(soapActions.IssaugotiParaiska2, paraiskosParams)
          .then((response) => {
            this.showSuccess(this.$t("soap.contractSaved"));
            // patch application with new value for "DraudimoImoka"
            // and possible update Buyer Name+Surname
            this.patchApplication({
              AsmensKodas: this.buyer()?.AsmensKodas || "",
              Vardas: this.buyer()?.Vardas || "",
              Pavarde: this.buyer()?.Pavarde || "",
              Sutuoktinis: this.applicationSettings.creditType,
              SutuoktinioAsmensKodas: this.buyerSpouse().SutuoktinioAsmensKodas || "",
              SutuoktinioVardas: this.buyerSpouse().SutuoktinioVardas || "",
              SutuoktinioPavarde: this.buyerSpouse().SutuoktinioPavarde || "",
              DraudimoImoka: response.DraudimoImoka,
            });

            resolve(response);
          })
          .catch((error) => {
            if (error.KlaidosKodas == "1100") {
              error.KlaidosPranesimas = "Neteisingas banko sąskaitos nr.";
              this.showError(error.KlaidosPranesimas);
              resolve(error);

              return;
            }

            this.showError(error.KlaidosPranesimas);

            reject(error);
          });
      });
    },
    soapGautiSutarti(paraiskosID: string) {
      const params: SoapBodyParams = {
        [soapActions.GautiSutarti2]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": paraiskosID,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiSutarti2, params)
        .then((response) => {
          this.patchApplication(response.Paraiska);

          return response.Paraiska;
        })
        .catch((error) => {
          this.showSoapError(error);

          return false;
        });
    },
    soapGautiSutikimoDokumenta2(asmensKodas: string, kieno = 1, fileName: string | TranslateResult = "sutikimas") {
      // GautiSutikimoDokumenta2
      const params: SoapBodyParams = {
        [soapActions.GautiSutikimoDokumenta2]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
          "ns1:asmensKodas": asmensKodas,
          "ns1:kieno": kieno,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiSutikimoDokumenta2, params)
        .then((response) => {
          this.downloadPDF(response.SutikimoPDF, `${fileName}.pdf`);
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapGautiSutikimoDokumentaSonT(asmensKodas: string) {
      const params: SoapBodyParams = {
        [soapActions.GautiSutikimoDokumentaSonT]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
          "ns1:asmensKodas": asmensKodas,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiSutikimoDokumentaSonT, params)
        .then((response) => {
          const result = JSON.parse(response.Duomenys);

          return result;
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapGautiSutartiesDokumentaSonT(applicationId: string) {
      const params: SoapBodyParams = {
        [soapActions.GautiSutartiesDokumentaSonT]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": applicationId,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiSutartiesDokumentaSonT, params)
        .then((response) => {
          const result = JSON.parse(response.Duomenys);

          return result;
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapGautiSiulomusVariantus() {
      const params: SoapBodyParams = {
        [soapActions.GautiSiulomusVariantus2]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
        },
      };

      return new Promise<GautiSiulomusVariantus2Response>((resolve, reject) => {
        new SoapClient()
          .sendRequest(soapActions.GautiSiulomusVariantus2, params)
          .then((response) => {
            const proposedOptions: ProposedOptionInterface[] = Array.isArray(response?.SiulomiVariantai.SiulomasVariantas2)
              ? response?.SiulomiVariantai.SiulomasVariantas2
              : [response?.SiulomiVariantai.SiulomasVariantas2];

            if (proposedOptions.length) {
              this.storeProposedOptions(proposedOptions);

              // update application refinance data if it returned
              if (response?.Refinansavimas?.length) {
                this.patchApplication({ Refinansavimas: response?.Refinansavimas });
              }

              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            if (error.KlaidosPranesimas) {
              let messages: string | string[] = "";
              let totalLines = 0;

              try {
                // when comes string with array or links sometimes fails parse() method
                try {
                  messages = JSON.parse(error.KlaidosPranesimas);
                  totalLines = messages.length;
                  messages = (messages as string[]).join("<br>");
                } catch {
                  // eslint-disable-next-line prettier/prettier
                  totalLines = error.KlaidosPranesimas.split("\",\"").length;

                  // restructure array into readable format
                  messages = error.KlaidosPranesimas.replace(/[\r\n]+/gm, "")
                    .replaceAll("&#x0D;", "")
                    // eslint-disable-next-line prettier/prettier
                    .replaceAll("[\"", "")
                    // eslint-disable-next-line prettier/prettier
                    .replaceAll("\"]", "")
                    // eslint-disable-next-line prettier/prettier
                    .replaceAll("\",\"", "<br/>");
                }

                switch (error.KlaidosKodas) {
                  case ApplicationStandardInfoErrors.ERROR_WITH_SIMPLE_HTML_MESSAGE:
                    this.showHtmlError(messages);
                    break;
                  case ApplicationStandardInfoErrors.ERROR_WITH_HTML_MESSAGE:
                    this.clearNotifications();
                    this.showHtmlSuccess({
                      htmlMessage: `${this.$t("soap.contractSaved")}!<br/>` + messages,
                      message: `${this.$t("newApplication.proposalsAdditionalInfo")}: ${totalLines}`,
                      collapsed: false,
                    });
                    break;
                  case ApplicationStandardInfoErrors.ERROR_WITH_MESSAGE:
                    this.showHtmlError(messages);
                    break;
                  case "default":
                    reject(error);
                    break;
                }

                if (
                  ApplicationProposedOptionsAllowedErrors.includes(error.KlaidosKodas) &&
                  (error?.SiulomiVariantai?.SiulomasVariantas2?.length || Object.keys(error?.SiulomiVariantai?.SiulomasVariantas2).length)
                ) {
                  this.storeProposedOptions(error.SiulomiVariantai.SiulomasVariantas2);
                }

                if (ApplicationStandardInfoAllowedErrors.includes(error.KlaidosKodas)) {
                  // update application refinance data if it returned
                  if (error?.Refinansavimas?.length) {
                    this.patchApplication({ Refinansavimas: error?.Refinansavimas });
                  }

                  resolve(error);
                } else {
                  reject(error);
                }
              } catch (klaidosKodas) {
                reject(klaidosKodas);
              }
            }
          });
      });
    }, // handle some special error
    soapGautiMokejimuGrafika(asmensKodas: number, fileName: string | TranslateResult = "mokejimu_grafikas") {
      const params: SoapBodyParams = {
        [soapActions.GautiMokejimuGrafikoDokumentaAsmenini3]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:asmensKodas": asmensKodas,
          "ns1:avansas": this.application().Avansas,
          "ns1:kopijuSkaicius": "1",
          "ns1:mokejimuDiena": this.application().MokejimoDiena,
          "ns1:salyguVariantoID": this.application().SalyguVariantoID,
          "ns1:suma": this.application().Suma,
          "ns1:trukme": this.application().Trukme,
          "ns1:sutuoktinioAsmensKodas": this.application()?.SutuoktinioAsmensKodas,
          "ns1:sutuoktinis": this.applicationSettings.creditType,
          "ns1:pajamos": this.application().Pajamos,
          "ns1:sutuoktinioPajamos": this.application().SutuoktinioPajamos,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiMokejimuGrafikoDokumentaAsmenini3, params)
        .then((response) => {
          this.downloadPDF(response.GrafikoPDF, `${fileName}.pdf`);
        })
        .catch((error) => {
          this.showSoapError(error);

          const errorMessages = JSON.parse(error.KlaidosPranesimas);
          if (errorMessages && errorMessages.length) {
            this.showError(errorMessages.join("\n"));
          }
        });
    },
    soapGautiStandartinesInformacijosDokumenta(fileName: string | TranslateResult = "standartine-informacija", kieno = 1) {
      const params: SoapBodyParams = {
        [soapActions.GautiStandartinesInformacijosDokumenta2]: {
          "ns1:kieno": kieno,
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiStandartinesInformacijosDokumenta2, params)
        .then((response) => {
          this.downloadPDF(response.StarndartinesInformacijosPDF, `${fileName}.pdf`);
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapGautiVertinimoAtaskaitosPDF(personalCode: string, fileName: string | TranslateResult = "vertinimo-ataskaita") {
      const params: SoapBodyParams = {
        [soapActions.GautiVertinimoAtaskaitosPDF]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
          "ns1:asmensKodas": personalCode,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiVertinimoAtaskaitosPDF, params)
        .then((response) => {
          this.downloadPDF(response.AtaskaitosPDF, `${fileName}.pdf`);
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapSudarytiSutarti() {
      const sudarytiSutartiParams: SoapBodyParams = {
        [soapActions.SudarytiSutarti2]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiska": {
            "ns1:ParaiskosID": this.application().ParaiskosID,
            "ns1:PardavejoID": this.currentSeller().PardavejoID,
            "ns1:PardavejoAtstovas": this.currentSeller().VardasPavarde,
            "ns1:SutartiesNr": this.application().SutartiesNr,
            "ns1:AsmensKodas": this.buyer().AsmensKodas,
            "ns1:Suma": this.application().Suma,
            "ns1:DokumentoNr": this.application().DokumentoNr,
            "ns1:Vardas": this.buyer().Vardas || "",
            "ns1:Pavarde": this.buyer().Pavarde || "",
            "ns1:DokumentoTipoID": this.application().DokumentoTipoID,
            "ns1:DokumentoGaliojimoData": this.application().DokumentoGaliojimoData,
            "ns1:SutuoktinioAsmensKodas": this.buyerSpouse().SutuoktinioAsmensKodas,
            "ns1:SutuoktinioVardas": this.buyerSpouse().SutuoktinioVardas || "",
            "ns1:SutuoktinioPavarde": this.buyerSpouse().SutuoktinioPavarde || "",
            "ns1:SutuoktinioDokumentoTipoID": this.application().SutuoktinioDokumentoTipoID,
            "ns1:SutuoktinioDokumentoNr": this.application().SutuoktinioDokumentoNr,
            "ns1:SutuoktinioDokumentoGaliojimoData": this.application().SutuoktinioDokumentoGaliojimoData,
            "ns1:Sutuoktinis": this.applicationSettings.creditType,
            "ns1:Pajamos": this.application().Pajamos,
            "ns1:MazesPajamos": this.application().MazesPajamos,
            "ns1:BusimosPajamos": this.application().BusimosPajamos,
            "ns1:SutuoktinioPajamos": this.application().SutuoktinioPajamos,
            "ns1:SutuoktinioMazesPajamos": this.application().SutuoktinioMazesPajamos,
            "ns1:SutuoktinioBusimosPajamos": this.application().SutuoktinioBusimosPajamos,
            "ns1:KlientoVartojimoPaskola": this.application().KlientoVartojimoPaskola,
            "ns1:KlientoBustoPaskola": this.application().KlientoBustoPaskola,
            "ns1:SalyguVariantoID": this.application().SalyguVariantoID,
            "ns1:Trukme": this.application().Trukme,
            "ns1:Gatve": this.application().Gatve,
            "ns1:Namas": this.application().Namas,
            "ns1:Butas": this.application().Butas,
            "ns1:Miestas": this.application().Miestas,
            "ns1:Indeksas": this.application().Indeksas,
            "ns1:Telefonas": this.application().Telefonas,
            "ns1:Telefonas2": this.application().Telefonas2,
            "ns1:SaskaitosNr": this.application().SaskaitosNr,
            "ns1:MokejimoDiena": this.application().MokejimoDiena,
            "ns1:Busena": this.application().Busena,
            "ns1:Data": this.application().Data,
            "ns1:DokumentuVariantai": this.application().DokumentuVariantai,
            "ns1:AtaskaitosTipas": this.application().AtaskaitosTipas,
            "ns1:AtsiemimoVietosID": this.application().AtsiemimoVietosID,
            "ns1:Avansas": this.application().Avansas,
            "ns1:AvansoTipas": this.application().AvansoTipas,
            "ns1:Butas2": this.application().Butas2,
            "ns1:DalyvaujaPolitikoje": this.application().DalyvaujaPolitikoje,
            "ns1:Darboviete": this.application().Darboviete,
            "ns1:DraudimoID": this.application().DraudimoID,
            "ns1:DraudimoImoka": this.application().DraudimoImoka,
            "ns1:PatikslintaDraudimoImoka": this.application().PatikslintaDraudimoImoka,
            "ns1:DraudimoPolisas": this.application().DraudimoPolisas,
            "ns1:DuomenuPolitikosGavimas": this.application().DuomenuPolitikosGavimas,
            "ns1:ElPastas": this.application().ElPastas,
            "ns1:Faktura": this.application().Faktura,
            "ns1:Gatve2": this.application().Gatve2,
            "ns1:GyvenamosVietosTipas": this.application().GyvenamosVietosTipas,
            "ns1:Indeksas2": this.application().Indeksas2,
            "ns1:InicijavimasGalimas": this.application().InicijavimasGalimas,
            "ns1:IsipareigojimuSuma": this.application().IsipareigojimuSuma,
            "ns1:IslaikytiniuSkaicius": this.application().IslaikytiniuSkaicius,
            "ns1:KitiKreditai": this.application().KitiKreditai,
            "ns1:KitosAplinkybes": this.application().KitosAplinkybes,
            "ns1:KlientoKitaPaskola": this.application().KlientoKitaPaskola,
            "ns1:KlientoLizingoPaskola": this.application().KlientoLizingoPaskola,
            "ns1:KontrolineEilute": this.application().KontrolineEilute,
            "ns1:KreditoLimitas": this.application().KreditoLimitas,
            "ns1:KreditoNaudojimas": this.application().KreditoNaudojimas,
            "ns1:Kreditoriai": this.application().Kreditoriai,
            "ns1:MazosImokos": this.application().MazosImokos,
            "ns1:Miestas2": this.application().Miestas2,
            "ns1:Namas2": this.application().Namas2,
            "ns1:MokejimuGrafikas": this.application().MokejimuGrafikas,
            "ns1:PajamuPazyma": this.application().PajamuPazyma,
            "ns1:PajamuTipas": this.application().PajamuTipas,
            "ns1:PajamuTipasSarasui": this.application().PajamuTipasSarasui,
            "ns1:PajamuVariantai": this.application().PajamuVariantai,
            "ns1:PapildomaSaskaita": this.application().PapildomaSaskaita,
            "ns1:PapildomasKodas": this.application().PapildomasKodas,
            "ns1:PapildomoKreditoPabaiga": this.application().PapildomoKreditoPabaiga,
            "ns1:PapildomoKreditoSuma": this.application().PapildomoKreditoSuma,
            "ns1:ParaiskosPasirasymas": this.application().ParaiskosPasirasymas,
            "ns1:ParduotuvesPavadinimas": this.application().ParduotuvesPavadinimas,
            "ns1:PasirasymoVieta": this.application().PasirasymoVieta,
            "ns1:PaskutinisPasirasymas": this.application().PaskutinisPasirasymas,
            "ns1:Pastabos": this.application().Pastabos,
            "ns1:Pilietybe": this.application().Pilietybe,
            "ns1:PiniguPanaudojimas": this.application().PiniguPanaudojimas,
            "ns1:PiniguPanaudojimoVariantai": this.application().PiniguPanaudojimoVariantai,
            "ns1:PlanuojaIsipareigojimu": this.application().PlanuojaIsipareigojimu,
            "ns1:PlanuojamuIsipareigojimuSuma": this.application().PlanuojamuIsipareigojimuSuma,
            "ns1:PolitikoPareigos": this.application().PolitikoPareigos,
            "ns1:PolitikoRysys": this.application().PolitikoRysys,
            "ns1:PolitikoVardasPavarde": this.application().PolitikoVardasPavarde,
            "ns1:Pranesimas": this.application().Pranesimas,
            "ns1:Refinansavimas": this.application().Refinansavimas,
            "ns1:SutikimasPasiulymu": this.application().SutikimasPasiulymu,
            "ns1:SutikimasPartneriu": this.application().SutikimasPartneriu,
            "ns1:SutikimasProfiliavimo": this.application().SutikimasProfiliavimo,
            "ns1:SutuoktinioSutikimasPasiulymu": this.application().SutuoktinioSutikimasPasiulymu,
            "ns1:SutuoktinioSutikimasPartneriu": this.application().SutuoktinioSutikimasPartneriu,
            "ns1:SutuoktinioSutikimasProfiliavimo": this.application().SutuoktinioSutikimasProfiliavimo,
            "ns1:SalyguVariantai": this.application().SalyguVariantai,
            "ns1:SalyguVariantasSarasui": this.application().SalyguVariantasSarasui,
            "ns1:SeimosNariuSkaicius": this.application().SeimosNariuSkaicius,
            "ns1:SeimyninePadetis": this.application().SeimyninePadetis,
            "ns1:SonTGalimas": this.application().SonTGalimas,
            "ns1:Stazas": this.application().Stazas,
            "ns1:SutartiesPasirasymas": this.application().SutartiesPasirasymas,
            "ns1:SutartiesTipas": this.application().SutartiesTipas,
            "ns1:SutuoktinioDarboviete": this.application().SutuoktinioDarboviete,
            "ns1:SutuoktinioDokumentuVariantai": this.application().SutuoktinioDokumentuVariantai,
            "ns1:SutuoktinioElPastas": this.application().SutuoktinioElPastas,
            "ns1:SutuoktinioKontrolineEilute": this.application().SutuoktinioKontrolineEilute,
            "ns1:SutuoktinioPajamuPazyma": this.application().SutuoktinioPajamuPazyma,
            "ns1:SutuoktinioPajamuTipas": this.application().SutuoktinioPajamuTipas,
            "ns1:SutuoktinioParaiskosPasirasymas": this.application().SutuoktinioParaiskosPasirasymas,
            "ns1:SutuoktinioPilietybe": this.application().SutuoktinioPilietybe,
            "ns1:SutuoktinioTelefonas": this.application().SutuoktinioTelefonas,
            "ns1:TikrinimoData": this.application().TikrinimoData,
            "ns1:TuriIsieskojimu": this.application().TuriIsieskojimu,
            "ns1:TuriIsipareigojimu": this.application().TuriIsipareigojimu,
            "ns1:TuriVelavimu": this.application().TuriVelavimu,
            "ns1:TurtoRusys": this.application().TurtoRusys,
            "ns1:Valiuta": this.application().Valiuta,
            "ns1:VardasKorteleje": this.application().VardasKorteleje,
            "ns1:Prekes": this.goods(),
            "ns1:KatalogoAkcijosIsimtis": this.application().KatalogoAkcijosIsimtis,
          },
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.SudarytiSutarti2, sudarytiSutartiParams)
        .then((response) => {
          this.patchApplication({
            DraudimoImoka: response.Paraiska.DraudimoImoka,
            SutartiesNr: response.Paraiska.SutartiesNr,
            Busena: response.Paraiska.Busena,
          });
          this.showSuccess(this.$t("newApplication.contractMade"));
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapGautiSutartiesDokumenta(
      fileName: string | TranslateResult = "sutartis",
      infoCount = 2,
      contractCount = 3,
      conditionsCount = 2,
      kieno = 1
    ) {
      const params: SoapBodyParams = {
        [soapActions.GautiSutartiesDokumenta2]: {
          "ns1:kieno": kieno,
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
          "ns1:standartinesInfoKopijuSkaicius": infoCount,
          "ns1:sutartiesKopijuSkaicius": contractCount,
          "ns1:salyguKopijuSkaicius": conditionsCount,
          "ns1:rikiavimas": 0,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiSutartiesDokumenta2, params)
        .then((response) => {
          this.downloadPDF(response.SutartiesPDF, `${fileName}.pdf`);
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapGautiSutartiesProjekta2(fileName: string | TranslateResult = "sutartis") {
      const params: SoapBodyParams = {
        [soapActions.GautiSutartiesProjekta2]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiSutartiesProjekta2, params)
        .then((response) => {
          this.downloadPDF(response.SutartiesPDF, `${fileName}.pdf`);
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapAtmestiSutarti() {
      const params: SoapBodyParams = {
        [soapActions.AtmestiSutarti2]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.AtmestiSutarti2, params)
        .then((response) => {
          this.showSuccess("Sutartis atmesta!");
          return response;
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapPerimtiSutarti(contractNumber: string) {
      const params: SoapBodyParams = {
        [soapActions.PerimtiSutarti]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:raktas": contractNumber,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.PerimtiSutarti, params)
        .then((response) => {
          return response.Paraiska;
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapGautiVartotojus(filterKey = "", sortDirection = "ASC", sortBy = "ParduotuvesPavadinimas") {
      const params: SoapBodyParams = {
        [soapActions.GautiVartotojus]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:raktas": filterKey,
          "ns1:kryptis": sortDirection,
          "ns1:stulpelis": sortBy,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiVartotojus, params)
        .then((response) => {
          // some users doesn't sees only one seller (usually himself)
          if (!response.Vartotojai.Vartotojas.length) {
            return [response.Vartotojai.Vartotojas];
          } else {
            return response.Vartotojai.Vartotojas;
          }
        })
        .catch(() => {
          return [];
        });
    },
    soapPerkeltiSutarti() {
      const params: SoapBodyParams = {
        [soapActions.PerkeltiSutarti]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
          "ns1:kriterijai": this.application().Kriterijai,
          "ns1:komentaras": this.application().Komentaras,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.PerkeltiSutarti, params)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.showSoapError(error);
          return false;
        });
    },
    soapIssaugotiPardaveja(seller: SellerInterface, password?: string) {
      const params: SoapBodyParams = {
        [soapActions.IssaugotiPardaveja2]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:aktyvus": seller.Aktyvus || 0,
          "ns1:autentifikacija": parseInt(seller.Autentifikacija || "0"),
          "ns1:emailas": seller.Emailas,
          "ns1:emailasPatvirtintas": seller.EmailasPatvirtintas || 0,
          "ns1:loginas": seller.Loginas,
          "ns1:mobilus": seller.MobilusTelefonas,
          "ns1:pardavejoID": seller.PardavejoID || 0,
          "ns1:parduotuvesID": seller.ParduotuvesID || 0,
          "ns1:vardas": seller.Vardas || "",
          "ns1:pavarde": seller.Pavarde || "",
          "ns1:telefonasPatvirtintas": seller.TelefonasPatvirtintas || 0,
          "ns1:slaptazodis": password,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.IssaugotiPardaveja2, params)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    soapGautiGalimasAtaskaitas() {
      const params: SoapBodyParams = {
        [soapActions.GautiGalimasAtaskaitas]: {
          "ns1:sesija": this.user()?.Sesija,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiGalimasAtaskaitas, params)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapGautiAtaskaitosDuomenis(id: number, parameters: ReportsFormParamsInterface[]) {
      const params: SoapBodyParams = {
        [soapActions.GautiAtaskaitosDuomenis]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:ataskaitosID": id,
          "ns1:parametrai": this._prepareSoapReportParameters(parameters),
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiAtaskaitosDuomenis, params)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    _prepareSoapReportParameters(parameters?: ReportsFormParamsInterface[]): GautiAtaskaitosDuomenisParametraiWrapperInterface | undefined {
      if (!parameters?.length) {
        return undefined;
      }

      const result: GautiAtaskaitosDuomenisParametraiInterface[] = [];

      parameters.forEach((element) => {
        result.push({
          "ns1:ParametroReiksmesID": element.param,
          "ns1:RodomaReiksme": element.value,
        });
      });

      return { "ns1:ParametroReiksme": result };
    },
    soapGautiSalyguVariantus() {
      const params: SoapBodyParams = {
        [soapActions.GautiSalyguVariantus2]: {
          "ns1:sesija": this.user()?.Sesija,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiSalyguVariantus2, params)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapGautiSalyguVariantusVadybininkui(sellerId: number) {
      const params: SoapBodyParams = {
        [soapActions.GautiSalyguVariantusVadybininkui]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:pardavejoID": sellerId,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiSalyguVariantusVadybininkui, params)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapSuskaiciuotiMokejimuGrafika(amount: number, duration: number, conditions: number) {
      const params: SoapBodyParams = {
        [soapActions.SuskaiciuotiMokejimuGrafika2]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:suma": amount,
          "ns1:trukme": duration,
          "ns1:salyguVariantoID": conditions,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.SuskaiciuotiMokejimuGrafika2, params)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapSuskaiciuotiMokejimuGrafikaAsmenini(
      amount: number,
      duration: number,
      conditions: number,
      familyStatus: number,
      familyMembers: number,
      mortgagePayments: number,
      creditPayments: number,
      income: number,
      spouseIncome: number,
      birthday: string
    ) {
      const params: SoapBodyParams = {
        [soapActions.SuskaiciuotiMokejimuGrafikaAsmenini2]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:suma": amount,
          "ns1:trukme": duration,
          "ns1:salyguVariantoID": conditions,
          "ns1:seimyninePadetis": familyStatus,
          "ns1:seimosNariuSkaicius": familyMembers,
          "ns1:klientoBustoPaskola": mortgagePayments,
          "ns1:klientoVartojimoPaskola": creditPayments,
          "ns1:gimimoData": birthday,
          "ns1:pajamos": income,
          "ns1:sutuoktinioPajamos": spouseIncome,
        },
      };

      return new Promise((resolve, reject) => {
        new SoapClient()
          .sendRequest(soapActions.SuskaiciuotiMokejimuGrafikaAsmenini2, params, ["ns1:gimimoData"])
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            // this.showSoapError(error);
            let errorMessages: string[] = [];

            if (error.KlaidosPranesimas) {
              errorMessages.push(error.KlaidosPranesimas);
              try {
                errorMessages = JSON.parse(error.KlaidosPranesimas);
              } catch (error) {
                // errorMessages.push("JSON parse error");
              }
            }

            // handle some special errors
            switch (error.KlaidosKodas) {
              case "1400":
                this.showHtmlError(errorMessages.join("<br>"));
                if (error.SiulomiVariantai && error.SiulomiVariantai.SiulomasVariantas2) {
                  this.storeProposedOptions(error.SiulomiVariantai.SiulomasVariantas2);
                }
                resolve(error); // reject here would prevent from redirecting to next step 4 -> 5
                break;
              default:
                this.showSoapError(error);
                break;
            }

            reject(error);
          });
      });
    },
    soapGautiMokejimuGrafikoDokumentaSuDiena(
      sum: number,
      duration: number,
      numberOfCopies = 1,
      conditionsVariantID: number,
      paymentDay: number,
      fileName: string | TranslateResult = "dokumentas"
    ) {
      const params: SoapBodyParams = {
        [soapActions.GautiMokejimuGrafikoDokumentaSuDiena]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:suma": sum,
          "ns1:trukme": duration,
          "ns1:mokejimuDiena": paymentDay,
          "ns1:kopijuSkaicius": numberOfCopies,
          "ns1:salyguVariantoID": conditionsVariantID,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiMokejimuGrafikoDokumentaSuDiena, params)
        .then((response) => {
          this.downloadPDF(response.GrafikoPDF, `${fileName}.pdf`);
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapAdresasAutocomplete(city: string, street = "", house = "", returnPostIndex = 0) {
      const params: SoapBodyParams = {
        [soapActions.AdresasAutocomplete]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:miestas": city,
          "ns1:gatve": street,
          "ns1:namas": house,
          "ns1:pastoIndeksas": returnPostIndex,
        },
      };
      return new SoapClient().sendRequest(soapActions.AdresasAutocomplete, params);
    },
    soapGautiGarantijuDraudimoKainas() {
      const params: SoapBodyParams = {
        [soapActions.GautiGarantijuDraudimoKainas]: {
          "ns1:sesija": this.user()?.Sesija,
        },
      };
      return new SoapClient().sendRequest(soapActions.GautiGarantijuDraudimoKainas, params);
    },
    soapKeistiSlaptazodi(session: string, password: string) {
      const params: SoapBodyParams = {
        [soapActions.KeistiSlaptazodi]: {
          "ns1:kodas": session,
          "ns1:slaptazodis": password,
        },
      };

      return new SoapClient().sendRequest(soapActions.KeistiSlaptazodi, params);
    },
    soapGautiParaiskosDokumentuSarasa(paraiskosID: number) {
      const params: SoapBodyParams = {
        [soapActions.GautiParaiskosDokumentuSarasa]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": paraiskosID,
        },
      };

      return new SoapClient().sendRequest(soapActions.GautiParaiskosDokumentuSarasa, params);
    },
    soapIkeltiParaiskosDokumenta(paraiskosID: number, documentType: number, content: string, fileExtension: string) {
      const params: SoapBodyParams = {
        [soapActions.IkeltiParaiskosDokumenta2]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": paraiskosID,
          "ns1:dokumentoTipas": documentType,
          "ns1:turinys": content,
          "ns1:bylosPletinys": fileExtension,
        },
      };

      return new SoapClient().sendRequest(soapActions.IkeltiParaiskosDokumenta2, params);
    },
    soapIstrintiParaiskosDokumenta(paraiskosID: number, documentID: number) {
      const params: SoapBodyParams = {
        [soapActions.IstrintiParaiskosDokumenta]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": paraiskosID,
          "ns1:dokumentoID": documentID,
        },
      };

      return new SoapClient().sendRequest(soapActions.IstrintiParaiskosDokumenta, params);
    },
    soapGautiParaiskosDokumenta(paraiskosID: number, dokumentoID: number, fileName = "test") {
      const params: SoapBodyParams = {
        [soapActions.GautiParaiskosDokumenta]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": paraiskosID,
          "ns1:dokumentoID": dokumentoID,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiParaiskosDokumenta, params)
        .then((response) => {
          this.downloadUploadedFile(response.Dokumentas.Turinys, `${fileName}`, response.Dokumentas.BylosPletinys ?? null);
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapTrintiParaiska(paraiskosID: number) {
      const params: SoapBodyParams = {
        [soapActions.TrintiParaiska]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": paraiskosID,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.TrintiParaiska, params)
        .then((response) => {
          console.log("resp", response);
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapIssaugotiKlientoEmaila(paraiskosID: number, email: string, spouse = 0) {
      const params: SoapBodyParams = {
        [soapActions.IssaugotiKlientoEmaila]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": paraiskosID,
          "ns1:emailas": email,
          "ns1:sutuoktinio": spouse,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.IssaugotiKlientoEmaila, params)
        .then((response) => {
          if (response.KlaidosKodas != "0") {
            this.showSoapError(response);
          }
          return response;
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapGautiSutartiesDokumentaPasirasyta(paraiskosID: number, filename: string | TranslateResult = "sutartis") {
      const params: SoapBodyParams = {
        [soapActions.GautiSutartiesDokumentaPasirasyta]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": paraiskosID,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiSutartiesDokumentaPasirasyta, params)
        .then((response) => {
          if (response.KlaidosKodas == "0" && String(response.KlaidosPranesimas).substring(0, 20) == "Dokumentas perkeltas") {
            this.showError(response.KlaidosPranesimas);
          } else {
            this.downloadPDF(response.SutartiesPDF, `${filename}.pdf`);
          }
        })
        .catch((error) => {
          if (error.KlaidosKodas == "2002") {
            this.showError(error.KlaidosPranesimas);
          }
        });
    },
    soapGautiSutikimoDokumentaPasirasyta(paraiskosID: number, personalCode: string, filename: string | TranslateResult = "sutikimas") {
      const params: SoapBodyParams = {
        [soapActions.GautiSutikimoDokumentaPasirasyta]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": paraiskosID,
          "ns1:asmensKodas": personalCode,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiSutikimoDokumentaPasirasyta, params)
        .then((response) => {
          this.downloadPDF(response.SutikimoPDF, `${filename}.pdf`);
        })
        .catch((error) => {
          if (error.KlaidosKodas == "2002") {
            this.showError(error.KlaidosPranesimas);
          }
        });
    },
    soapSiustiDokumentus(paraiskosID: number) {
      const params: SoapBodyParams = {
        [soapActions.SiustiDokumentus]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": paraiskosID,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.SiustiDokumentus, params)
        .then(() => {
          this.showSuccess("Dokumentai išsiųsti");
        })
        .catch((error) => this.showError(error.KlaidosPranesimas));
    },
    async soapPatvirtintiPasirasyma(paraiskosID: number) {
      const params: SoapBodyParams = {
        [soapActions.PatvirtintiPasirasyma]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": paraiskosID,
        },
      };

      return await new SoapClient()
        .sendRequest(soapActions.PatvirtintiPasirasyma, params)
        .then((response) => {
          this.showSuccess(this.$t("soap.contractSaved"));
          return response;
        })
        .catch((error) => {
          this.showSoapError(error);
          return false;
        });
    },
    soapPratestiIgaliojima() {
      const params: SoapBodyParams = {
        [soapActions.PratestiIgaliojima]: {
          "ns1:sesija": this.user()?.Sesija,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.PratestiIgaliojima, params)
        .then(() => {
          this.showSuccess("Įmonės įgaliojimas sėkmingai atnaujintas. Gerų darbų!");
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapPerduotiPasirasytaSutikimoDokumenta(fileContent: string) {
      const params: SoapBodyParams = {
        [soapActions.PerduotiPasirasytaSutikimoDokumenta2]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
          "ns1:dokumentas": fileContent,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.PerduotiPasirasytaSutikimoDokumenta2, params)
        .then(() => {
          this.showSuccess(this.$t("soap.signedAgreementDocumentSuccess"));
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapPerduotiPasirasytaSutartiesDokumenta(fileContent: string) {
      const params: SoapBodyParams = {
        [soapActions.PerduotiPasirasytaSutartiesDokumenta2]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
          "ns1:dokumentas": fileContent,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.PerduotiPasirasytaSutartiesDokumenta2, params)
        .then(() => {
          this.showSuccess(this.$t("soap.signedContractDocumentSuccess"));
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapGautiParaiskosVeiksmuZurnala(paraiskosID: number) {
      const params: SoapBodyParams = {
        [soapActions.GautiParaiskosVeiksmuZurnala]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": paraiskosID,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiParaiskosVeiksmuZurnala, params)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapInicijuotiParaiskosPasirasyma() {
      const params: SoapBodyParams = {
        [soapActions.InicijuotiParaiskosPasirasyma]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.InicijuotiParaiskosPasirasyma, params)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapInicijuotiSutuoktinioParaiskosPasirasyma() {
      const params: SoapBodyParams = {
        [soapActions.InicijuotiSutuoktinioParaiskosPasirasyma]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.InicijuotiSutuoktinioParaiskosPasirasyma, params)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapInicijuotiPasirasyma() {
      const params: SoapBodyParams = {
        [soapActions.InicijuotiPasirasyma]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.InicijuotiPasirasyma, params)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.showSoapError(error);
          return error;
        });
    },
    soapAtsauktiPasirasyma() {
      const params: SoapBodyParams = {
        [soapActions.AtsauktiPasirasyma]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.AtsauktiPasirasyma, params)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapAtblokuotiVartotoja(login: string) {
      const params: SoapBodyParams = {
        [soapActions.AtblokuotiVartotoja]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:loginas": login,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.AtblokuotiVartotoja, params)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapNaujaMinimaliKreditoSuma(senaKreditoSuma: number | null, naujaKreditoSuma: number | null) {
      const params: SoapBodyParams = {
        [soapActions.NaujaMinimaliKreditoSuma]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
          "ns1:senaKreditoSuma": senaKreditoSuma || 0,
          "ns1:naujaKreditoSuma": naujaKreditoSuma || 0,
          "ns1:senaDraudimoImoka": this.application().DraudimoImoka || 0,
        },
      };

      return new Promise((resolve, reject) => {
        new SoapClient()
          .sendRequest(soapActions.NaujaMinimaliKreditoSuma, params)
          .then((response: any) => {
            if (response.KlaidosKodas == SoapOK) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            this.showSoapError(error);
          });
      });
    },
    soapIsrasoStatusas(personalCode: string, paraiskosID: number) {
      const params: SoapBodyParams = {
        [soapActions.IsrasoStatusas]: {
          "ns1:sesija": this.user().Sesija,
          "ns1:asmensKodas": personalCode,
          "ns1:paraiskosID": paraiskosID,
        },
      };

      return new Promise<IsrasoStatusasResponseInterface>((resolve, reject) => {
        new SoapClient()
          .sendRequest(soapActions.IsrasoStatusas, params)
          .then((response: any) => {
            if (response.KlaidosKodas == SoapOK) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    soapIsrasaInicijuoti(personalCode: string, paraiskosID: number) {
      const params: SoapBodyParams = {
        [soapActions.IsrasaInicijuoti]: {
          "ns1:sesija": this.user().Sesija,
          "ns1:asmensKodas": personalCode,
          "ns1:paraiskosID": paraiskosID,
        },
      };

      return new Promise((resolve, reject) => {
        new SoapClient()
          .sendRequest(soapActions.IsrasaInicijuoti, params)
          .then((response: any) => {
            if (response.KlaidosKodas == SoapOK) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => reject(error));
      });
    },
    soapServisuStatusas() {
      const params: SoapBodyParams = {
        [soapActions.ServisuStatusas]: {
          "ns1:sesija": this.user()?.Sesija,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.ServisuStatusas, params)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapGautiSBDSutikimoDokumenta(fileName: string | TranslateResult = "sb-draudimo-sutikimas") {
      const params: SoapBodyParams = {
        [soapActions.GautiSBDSutikimoDokumenta]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiSBDSutikimoDokumenta, params)
        .then((response) => {
          this.downloadPDF(response.SutikimoPDF, `${fileName}.pdf`);
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapGautiPajamuSarasa(spouse = false) {
      const params: SoapBodyParams = {
        [soapActions.GautiPajamuSarasa]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
          "ns1:sutuoktinis": spouse ? 1 : 0,
        },
      };

      return new Promise<IncomeDataWithTotalsInterface>((resolve, reject) => {
        new SoapClient()
          .sendRequest(soapActions.GautiPajamuSarasa, params)
          .then((response: IncomeDataWithTotalsInterface) => {
            resolve(response);
          })
          .catch((error) => {
            this.showSoapError(error);
            reject(error);
          });
      });
    },
    soapIssaugotiPajamuSarasa(incomeList: IncomeDataInterface[] = []) {
      const params: SoapBodyParams = {
        [soapActions.IssaugotiPajamuSarasa]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
          "ns1:pajamos": this._prepareSoapIssaugotiPajamuSarasaParameters(incomeList),
        },
      };

      return new Promise((resolve, reject) => {
        new SoapClient()
          .sendRequest(soapActions.IssaugotiPajamuSarasa, params)
          .then((response) => {
            if (response.KlaidosKodas == SoapOK) {
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            this.showSoapError(error);
            reject(error);
          });
      });
    },
    soapTikrintiEmaila(email: string) {
      const params: SoapBodyParams = {
        [soapActions.TikrintiEmaila]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:elPastas": email,
        },
      };

      return new Promise<boolean>((resolve, reject) => {
        return new SoapClient()
          .sendRequest(soapActions.TikrintiEmaila, params)
          .then((response: string) => {
            resolve(+response === 0);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    soapGautiSBDSutikimoDokumentaSonT() {
      const params: SoapBodyParams = {
        [soapActions.GautiSBDSutikimoDokumentaSonT]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.GautiSBDSutikimoDokumentaSonT, params)
        .then((response) => {
          const result = JSON.parse(response.Duomenys);

          return result;
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapPerduotiPasirasytaDraudimoDokumenta(fileContent: string) {
      const params: SoapBodyParams = {
        [soapActions.PerduotiPasirasytaDraudimoDokumenta]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:paraiskosID": this.application().ParaiskosID,
          "ns1:dokumentas": fileContent,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.PerduotiPasirasytaDraudimoDokumenta, params)
        .then(() => {
          this.showSuccess(this.$t("soap.signedInsuranceAgreementDocumentSuccess"));
        })
        .catch((error) => {
          this.showSoapError(error);
        });
    },
    soapSutartisGalima() {
      const params: SoapBodyParams = {
        [soapActions.SutartisGalima]: {
          "ns1:sesija": this.user()?.Sesija,
          "ns1:loginas": this.user()?.Loginas,
          "ns1:salyguVariantoID": this.application().SalyguVariantoID,
          "ns1:suma": this.application().Suma,
          "ns1:vardas": this.application().Vardas,
          "ns1:pavarde": this.application().Pavarde,
          "ns1:trukme": this.application().Trukme,
        },
      };

      return new SoapClient()
        .sendRequest(soapActions.SutartisGalima, params)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return error;
        });
    },
    _addBlankTimeToDate(value: string, defaultValue = "") {
      if (!value) {
        return defaultValue;
      }

      const parts = value.split(" ");
      if (parts.length === 2) {
        return value;
      }

      return `${parts} 00:00:00`;
    },
    _prepareSoapIssaugotiPajamuSarasaParameters(
      parameters?: IncomeDataInterface[]
    ): IssaugotiPajamuSarasaIrasoInterfaceWrapperInterface | null {
      if (!parameters?.length) {
        return null;
      }

      const result: IssaugotiPajamuSarasaIrasoInterface[] = [];

      parameters.forEach((element) => {
        result.push({
          "ns1:DataIki": this._addBlankTimeToDate(element.DataIki),
          "ns1:DataNuo": this._addBlankTimeToDate(element.DataNuo),
          "ns1:PajamosID": element.PajamosID ? +element.PajamosID : null,
          "ns1:PajamuRusiesID": +element.PajamuRusiesID,
          "ns1:PajamuSuma": parseFloat(element.PajamuSuma),
          "ns1:PatikslintaSuma": parseFloat(element.PatikslintaSuma),
          "ns1:Pavadinimas": element.Pavadinimas,
          "ns1:Saltinis": +element.Saltinis,
          "ns1:Sutuoktinis": +element.Sutuoktinis,
          "ns1:Tikslinta": +element.Tikslinta,
          "ns1:TrintiEilute": +element.TrintiEilute,
        });
      });

      return { "ns1:Pajama": result };
    },
  },
});

export default soapRequests;
